import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Post from "../components/Post.js";
import Banner from "../components/Banner";
import { getListPromotionPostFromAction } from "../actions/postActions";
import Message from "../components/Message";
import Loader from "../components/Loader";
import "../liststyle.css";

const PromotionScreen = () => {
  const dispatch = useDispatch();

  //dispatch request to Action
  useEffect(() => {
    dispatch(getListPromotionPostFromAction());
  }, [dispatch]);

  //Lấy danh sách post theo category Promotion ID=105
  const postPromotionListFromScreen = useSelector(
    (state) => state.postPromotionListFromStore
  );
  const { loading, error, postsPromotionData } = postPromotionListFromScreen;

  return (
    <>
      {loading ? (
        <div className="loading-full">
          <Loader />
        </div>
      ) : (
        <>
        <div>
        {/* Nếu kích thước màn hình >= MD */}
        {window.innerWidth >= 768 && (
          <div className="container content-mt mb-2">
            <div className="row justify-content-center">
              <div className="col-md-9">
                {error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <>
                    {postsPromotionData.map(
                      (
                        post //duyet tung promotion post
                      ) => (
                        <Post key={post.id} type="promotion" post={post} />
                      )
                    )}
                  </>
                )}
              </div>
              <div className="col-md-3">
                <Banner banner_app_type={"web"} banner_type={"banner_product"} />
              </div>
            </div>
          </div>
        )}

        {/* Nếu kích thước màn hình < MD */}
        {window.innerWidth < 768 && (
          <div className="container content-mt mb-2">
            <div className="row justify-content-center">
              <div className="col-md-3">
                <Banner banner_app_type={"web"} banner_type={"banner_product"} />
              </div>
              <div className="col-md-9">
                {error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <>
                    {postsPromotionData.map(
                      (
                        post //duyet tung promotion post
                      ) => (
                        <Post key={post.id} type="promotion" post={post} />
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        </div>
        </>
      )}
    </>
  );
};

export default PromotionScreen;
