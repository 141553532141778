import React from 'react';
import EmbeddedPage from '../components/EmbeddedPage';

function PrivacyScreen() {
  return (
    <div>
      <EmbeddedPage url="https://cms.kojiesan.vn/privacy/" title="Chính sách bảo mật" />
    </div>
  );
}

export default PrivacyScreen;
