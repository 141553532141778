import React, { createContext, useState } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductDetailScreen from "./screens/ProductDetailScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import InitPasswordWithOTPScreen from "./screens/InitPasswordWithOTPScreen";
import GetOTPResetPasswordScreen from "./screens/GetOTPResetPasswordScreen";
import ResetPasswordWithOTPScreen from "./screens/ResetPasswordWithOTPScreen";
import LoyaltyScreen from "./screens/LoyaltyScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import OrderListScreen from "./screens/OrderListScreen";
import OrderOTPScreen from "./screens/OrderOTPScreen";
import PostListScreen from "./screens/PostListScreen";
import PostDetailScreen from "./screens/PostDetailScreen";
import PrivacyScreen from "./screens/PrivacyScreen";
import OrderGuideScreen from "./screens/OrderGuideScreen";
import PurchasePolicyScreen from "./screens/PurchasePolicyScreen";
import LoyaltyPolicyScreen from "./screens/LoyaltyPolicyScreen";




//Export LanguageContext để sử dụng cho các màn hình khác
export const LanguageContext = createContext();

const App = () => {
  //Biến dùng chung isVietnamese (ngôn ngữ được chọn phải tiếng Việt không)
  const [isVietnamese, setIsVietnamese] = useState(true);

  return (
      //Khai báo LanguageContext vào ứng dụng và gán biến dùng chung isVietnamese (ngôn ngữ được chọn phải tiếng Việt không) vào LanguageContext
      <LanguageContext.Provider value={{ isVietnamese, setIsVietnamese}}>
        <Router>
          <Header />
          <main className="content">
            <Route path="/register" component={RegisterScreen} />
            <Route path="/initpass" component={InitPasswordWithOTPScreen} />
            <Route path="/getotpresetpass" component={GetOTPResetPasswordScreen} />
            <Route path="/initresetpass" component={ResetPasswordWithOTPScreen} />
            <Route path="/login" component={LoginScreen} />
            <Route path="/loyalty" component={LoyaltyScreen} />
            <Route path="/" component={HomeScreen} exact />
            <Route path="/products" component={ProductListScreen} />
            <Route path="/product/:id" component={ProductDetailScreen} />
            <Route path="/cart/:id?" component={CartScreen} />
            <Route path="/shipping" component={ShippingScreen} />
            <Route path="/placeorder" component={PlaceOrderScreen} />
            <Route path="/order/:id" component={OrderDetailScreen} />
            <Route path="/orderotp" component={OrderOTPScreen} />
            <Route path="/postlist" component={PostListScreen} />
            <Route path="/post/:id" component={PostDetailScreen} />
            <Route path="/orderlist" component={OrderListScreen} />
            <Route path="/privacy" component={PrivacyScreen} />
            <Route path="/orderguide" component={OrderGuideScreen} />
            <Route path="/purchasepolicy" component={PurchasePolicyScreen} />
            <Route path="/loyaltypolicy" component={LoyaltyPolicyScreen} />
          </main>
          <Footer />
        </Router>
      </LanguageContext.Provider>
  );
};

export default App;
