import React from 'react';
import EmbeddedPage from '../components/EmbeddedPage';

function OrderGuideScreen() {
  return (
    <div>
      <EmbeddedPage url="https://cms.kojiesan.vn/huong-dan-mua-hang/" title="Hướng dẫn mua hàng" />
    </div>
  );
}

export default OrderGuideScreen;
