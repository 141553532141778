import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Container } from "react-bootstrap";
import Message from "../components/Message";
import { getOTPResetPass } from "../actions/userActions";

const GetOPTResetPasswordScreen = ({ location, history }) => {
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (username === "") {
      setMessage("Vui long nhap so dien thoai");
    }else{
      dispatch(getOTPResetPass(username))
      //Sau khi gửi OTP thì hiển thị màn hình reset mật khẩu
      history.push(`/initresetpass/?username=${username}`)
    }  
  };

  return (
    <div className="bg-red">
      <Container
        style={{
          minHeight: 700,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="box-login">
          <h1 className="titleSlide h3 mb-4">Tạo lại mật khẩu</h1>
          {message && <Message variant="danger">{message}</Message>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="username">
              <Form.Label className="titleProduct mx-1">
                Số điện thoại
              </Form.Label>
              <Form.Control
                type="phone"
                placeholder="Nhập số điện thoại"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              className="btn btn-red w-100"
            >
              Tạo lại mật khẩu
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default GetOPTResetPasswordScreen;
