import axios from 'axios'
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_INFO,
  GET_OTP_TO_ORDER_REQUEST,
  GET_OTP_TO_ORDER_SUCCESS,
  GET_OTP_TO_ORDER_FAIL,
} from '../constants/cartConstants'
import {
  CMS_URL,
} from '../constants/webConstants'
import { saveGuestInfo } from '../actions/userActions'

export const addToCart = (id, quantity) => async (dispatch, getState) => {

  // const {
  //   userInfoFromStore: { userInfoData },
  // } = getState();

  
  // KH đã đăng nhập hay không đều có quyền thêm vào giỏ hàng
  const { data } = await axios.get(
    `${CMS_URL}/wp-json/wc/v3/products/${id}`,
    {
      headers: {
        accept: 'application/json',
        // token: `Bearer ${userInfoData.data.token}`,
        // Authorization: userInfoData.data.basic_auth,
      },
    }
  )

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product_id: data.id,
      slug: data.slug,
      name: data.name,
      image: data.images[0].src,
      price: data.price,
      //countInStock: data.stock_status,
      countInStock: 10, //TODO: hardcode số lượng sản phẩm tối đa có thể thêm vào giỏ hàng =10
      quantity,
    },
  })

  // localStorage.setItem('cartItemsFromStorage', JSON.stringify(getState().cartItemsData))
}

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  })

  localStorage.setItem('cartItemsFromStorage', JSON.stringify(getState().cartFromStore.cartItemsData))
}

export const saveShippingInfo = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_INFO,
    payload: data,
  })

  //Gọi hàm userActions->saveGuestInfo để data vào guestInfoFromStore, guestInfoFromStorage
  dispatch(saveGuestInfo(data))

  localStorage.setItem('shippingAddressFromStorage', JSON.stringify(data.address))
  localStorage.setItem('paymentMethodFromStorage', JSON.stringify(data.paymentMethod))
  localStorage.setItem('orderNoteFromStorage', JSON.stringify(data.orderNote))
}

export const getOrderOTP = (username, history) => async (dispatch) => {
  try {
    dispatch({
      type: GET_OTP_TO_ORDER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/kojiesan/v2/getorderotp/` + username,
      config
    );

    dispatch({
      type: GET_OTP_TO_ORDER_SUCCESS,
      payload: data,
    });

    //Sau khi gửi OTP để tạo đơn hàng thành công thì gọi hiển thị trang nhập OTP để đặt hàng
    history.push("/orderotp")

  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    //Hiển thị thông báo lỗi tại màn hình shipping
    history.push("/shipping?message="+message)
    dispatch({
      type: GET_OTP_TO_ORDER_FAIL,
      payload: message,
    });
  }
}
