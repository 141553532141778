import {
  PROMOTION_POST_LIST_REQUEST,
  PROMOTION_POST_LIST_SUCCESS,
  PROMOTION_POST_LIST_FAIL,
  TIP_POST_LIST_REQUEST,
  TIP_POST_LIST_SUCCESS,
  TIP_POST_LIST_FAIL,
  POST_DETAIL_REQUEST,
  POST_DETAIL_SUCCESS,
  POST_DETAIL_FAIL,
} from '../constants/postConstants'

export const postPromotionListFromReducer = (state = { postsPromotionData: [] }, action) => {
  switch (action.type) {
    case PROMOTION_POST_LIST_REQUEST:
      return { loading: true, postsPromotionData: [] }
    case PROMOTION_POST_LIST_SUCCESS:
      return {
        loading: false,
        postsPromotionData: action.payload,
      }
    case PROMOTION_POST_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postTipListFromReducer = (state = { postsTipData: [] }, action) => {
  switch (action.type) {
    case TIP_POST_LIST_REQUEST:
      return { loading: true, postsTipData: [] }
    case TIP_POST_LIST_SUCCESS:
      return {
        loading: false,
        postsTipData: action.payload,
      }
    case TIP_POST_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postDetailsData = (
  state = { postDetailsData: {} },
  action
) => {
  switch (action.type) {
    case POST_DETAIL_REQUEST:
      return { ...state, loading: true }
    case POST_DETAIL_SUCCESS:
      return { loading: false, postDetailsData: action.payload }
    case POST_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
