import React from 'react';
import EmbeddedPage from '../components/EmbeddedPage';

function OrderGuideScreen() {
  return (
    <div>
      <EmbeddedPage url="https://cms.kojiesan.vn/chinh-sach-mua-hang/" title="Chính sách mua hàng" />
    </div>
  );
}

export default OrderGuideScreen;
