import React from 'react';
import EmbeddedPage from '../components/EmbeddedPage';

function OrderGuideScreen() {
  return (
    <div>
      <EmbeddedPage url="https://cms.kojiesan.vn/chuong-trinh-hoi-vien/" title="Chương trình hội viên" />
    </div>
  );
}

export default OrderGuideScreen;
