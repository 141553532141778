export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_REGISTER_WITH_ORDER_REQUEST = 'USER_REGISTER_WITH_ORDER_REQUEST'
export const USER_REGISTER_WITH_ORDER_SUCCESS = 'USER_REGISTER_WITH_ORDER_SUCCESS'
export const USER_REGISTER_WITH_ORDER_FAIL = 'USER_REGISTER_WITH_ORDER_FAIL'

export const USER_INIT_PASSWORD_WITH_OTP_REQUEST = 'USER_INIT_PASSWORD_WITH_OTP_REQUEST'
export const USER_INIT_PASSWORD_WITH_OTP_SUCCESS = 'USER_INIT_PASSWORD_WITH_OTP_SUCCESS'
export const USER_INIT_PASSWORD_WITH_OTP_FAIL = 'USER_INIT_PASSWORD_WITH_OTP_FAIL'



export const GET_OTP_RESET_PASS_REQUEST = 'GET_OTP_RESET_PASS_REQUEST'
export const GET_OTP_RESET_PASS_SUCCESS = 'GET_OTP_RESET_PASS_SUCCESS'
export const GET_OTP_RESET_PASS_FAIL = 'GET_OTP_RESET_PASS_FAIL'

export const USER_RESET_PASSWORD_WITH_OTP_REQUEST = 'USER_RESET_PASSWORD_WITH_OTP_REQUEST'
export const USER_RESET_PASSWORD_WITH_OTP_SUCCESS = 'USER_RESET_PASSWORD_WITH_OTP_SUCCESS'
export const USER_RESET_PASSWORD_WITH_OTP_FAIL = 'USER_RESET_PASSWORD_WITH_OTP_FAIL'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const GUEST_INFO_SUCCESS = 'GUEST_INFO_SUCCESS'

export const CHECK_OTP_TO_ORDER_REQUEST = 'CHECK_OTP_TO_ORDER_REQUEST'
export const CHECK_OTP_TO_ORDER_SUCCESS = 'CHECK_OTP_TO_ORDER_SUCCESS'
export const CHECK_OTP_TO_ORDER_FAIL = 'CHECK_OTP_TO_ORDER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const CUSTOMER_POINTS_AND_MEMBERSHIP_SUCCESS = 'CUSTOMER_POINTS_AND_MEMBERSHIP_SUCCESS'

export const CUSTOMER_LIST_POINT_REQUEST = 'CUSTOMER_LIST_POINT_REQUEST'
export const CUSTOMER_LIST_POINT_SUCCESS = 'CUSTOMER_LIST_POINT_SUCCESS'
export const CUSTOMER_LIST_POINT_FAIL= 'CUSTOMER_LIST_POINT_FAIL'