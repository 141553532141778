import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Container,
} from "react-bootstrap";
import Message from "../components/Message";
import { addToCart, removeFromCart } from "../actions/cartActions";

const CartScreen = ({ match, location, history }) => {
  const product_id = match.params.id;

  const quantity = location.search ? Number(location.search.split("=")[1]) : 1;

  const dispatch = useDispatch();

  const cartFromScreen = useSelector((state) => state.cartFromStore);
  const { cartItemsData } = cartFromScreen;

  useEffect(() => {
    if (product_id) {
      dispatch(addToCart(product_id, quantity));
    }
  }, [dispatch, product_id, quantity]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    history.push("/shipping");
  };

  return (
    <Container
      className="my-5"
      style={{ maxWidth: 1400, minHeight: 500, fontSize: 14 }}
    >
      <Row>
        <div className="col-12 col-lg-4">
          <h1 className="titleSlide h4 mb-4 text-center text-lg-left">Giỏ Hàng</h1>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <p className="mb-3 text-left fw-normal">
                  Giỏ hàng có{" "}
                  {cartItemsData.reduce(
                    (acc, item) => acc + item.quantity,
                    0
                  )}{" "}
                  sản phẩm
                </p>
                <p className="h5 mb-3 text-left">
                  {"Tổng tiền: "}
                  {cartItemsData
                    .reduce((acc, item) => acc + item.quantity * item.price, 0)
                    .toLocaleString("vi", {
                      style: "currency",
                      currency: "VND",
                    })}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  type="button"
                  className="btn btn-red"
                  disabled={cartItemsData.length === 0}
                  onClick={checkoutHandler}
                >
                  Tiến hành đặt hàng
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
        <div className="col-12 col-lg-8 mb-5">
          <h1 className="titleSlide h4 mb-4 text-left">Sản phẩm</h1>
          {cartItemsData.length === 0 ? (
            <Message>
              Chưa có sản phẩm trong giỏ hàng <Link to="/">Quay lại</Link>
            </Message>
          ) : (
            <ListGroup variant="flush">
              {cartItemsData.map((item) => (
                <ListGroup.Item key={item.product_id}>
                  <Row>
                    <div className="col-4 col-md-3 col-lg-2 d-flex justify-content-center align-items-center">
                      <Image
                        src={item.image}
                        alt={item.name}
                        rounded
                        width={150}
                      />
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => removeFromCartHandler(item.product_id)}
                        className="cartRemove"
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </div>
                    <div className="col">
                      <Row className="mb-3">
                        <Link
                          to={`/product/${item.slug}`}
                          className="titleProduct"
                        >
                          {item.name}
                        </Link>
                      </Row>
                      <Row>
                        <Col>
                          {parseInt(item.price).toLocaleString("vi", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={item.quantity}
                            onChange={(e) =>
                              dispatch(
                                addToCart(
                                  item.product_id,
                                  Number(e.target.value)
                                )
                              )
                            }
                          >
                            {[...Array(item.countInStock).keys()].map((x) => (
                              <option key={x + 1} value={x + 1}>
                                {x + 1}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default CartScreen;
