import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import AutoRequestServer from "../components/AutoRequestServer";
import {
  HiOutlineShoppingBag,
  HiOutlineX,
  HiOutlineMenu,
  HiOutlineUser,
} from "react-icons/hi";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import {
  getMessageSettings,
} from "../actions/productActions";
//Import LanguageContext được export từ App.js
import { LanguageContext } from '../App';

const Header = () => {
  const [isToggle, setToggle] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  /*CHÚ Ý QUAN TRỌNG
  - Sau khi login thành công thì lưu thông tin user vào đối tượng userInfoData (ở hàm userReducer.js -> userLoginReducer)
  - Đối tượng này được định nghĩa trong store.js
  - Đối tượng này sẽ mất đi khi chuyển/refesh trang 
  -> Để sử dụng đối tượng userInfoData trong các màn hình mà không cần dispatch lại API userLogin 
  -> Thì trong store.js sử dụng Local storage để lưu đối tượng userInfoFromStorage
      const userInfoFromStorage = localStorage.getItem('userInfoFromStorage')
      ? JSON.parse(localStorage.getItem('userInfoFromStorage'))
      : null
  - Sau đó hàm initialState sẽ gán đối tượng Local Storage userInfoFromStorage vào các đối tượng trong userInfoData
    const initialState = {
      cartFromStore: {
        cartItemsData: cartItemsFromStorage,
      },
      userInfoFromStore: { userInfoData: userInfoFromStorage },
      messageSettingsFromStore: messageSettingsFromStorage,
    }
  - Đối tượng này sẽ được sử dụng để kiểm tra user đã đăng nhập chưa và được sử dụng trong các components dùng chung như Header.js
  - Khác với đối tượng userInfoData (ở hàm userReducer.js -> userRegisterReducer) được dùng trong RegisterScreen
*/
  const userLoginFromScreen = useSelector((state) => state.userInfoFromStore);
  const { userInfoData } = userLoginFromScreen;
  const userid = userInfoData ? userInfoData.data.userid : "";

  const cart = useSelector((state) => state.cartFromStore);

  //Lấy ngôn ngữ được chọn có phải tiếng Việt không từ biến dùng chung isVietnamese trong LanguageContext được khai báo trong App.js
  const { isVietnamese, setIsVietnamese } = useContext(LanguageContext);

  //messageSettingData chứa thông tin message như Trang chủ, Sản phẩm... cho các menu và label
  const messageSettingsFromScreen = useSelector((state) => state.messageSettingsFromStore);
  const { messageSettingData } = messageSettingsFromScreen;

  useEffect(() => {
    //Gọi API getMessageSetting để lấy thông tin message như Trang chủ, Sản phẩm... cho các menu và label
    if(!messageSettingData && !localStorage.getItem('messageSettingFromStorage')){
      dispatch(getMessageSettings())
    }
  }, [dispatch, messageSettingData])

  const logoutHandler = () => {
    dispatch(logout()); //Khi nhấn vào chữ Logout trên menu thì sẽ gọi đối tượng {logout} từ userActions
  };

  const handleToggle = () => {
    setToggle(!isToggle);
  };

  const handleOpen = () => {
    setOpen(!isOpen);
  };

  const languageHandler = () => {
    setIsVietnamese(!isVietnamese)
  };

  return (
    <div className="menuTop">
      {/* Nếu KH đã đăng nhập thì mỗi 1 phút component AutoRequestServer sẽ gọi request đến backend
    để lấy điểm tích luỹ-hạng thành viên và điểm đã sử dụng */}
      {userInfoData && <AutoRequestServer userid={userid} />}
      <div className="boxMenuTop p-1">
        <Swiper
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
        >
          <SwiperSlide>
            <p className="textMenuTop">
                Đăng ký liền tay tích ngay điểm thưởng
              <a href="/loyalty" className="linkMenuTop px-2">
                Learn More...
              </a>
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <p className="textMenuTop">Khuyến mãi tháng {new Date().getMonth() + 1}...
              <a href="/postlist" className="linkMenuTop px-2">
                Learn More...
              </a>
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="boxNavMenuTop">
        <div className="row navMenu">
          {/* menu trai */}
          <div className="col justify-content-start">
            <ul className="menu">
              {isToggle ? (
                <li className="d-block d-lg-none">
                  <a onClick={handleToggle}>
                    <HiOutlineX className="iconMenu" />
                  </a>
                </li>
              ) : (
                <li className="d-block d-lg-none">
                  <a onClick={handleToggle}>
                    <HiOutlineMenu className="iconMenu" />
                  </a>
                </li>
              )}
              <div
                className={
                  isToggle
                    ? "d-lg-block mobiMenuL"
                    : "d-none d-lg-block mobiMenuL"
                }
              >
                <li>
                  <a href="/">{messageSettingData && (isVietnamese ? JSON.parse(messageSettingData[0].vietnamese).home : JSON.parse(messageSettingData[0].english).home)}</a>
                </li>
                <li>
                  <a href="/products">{messageSettingData && (isVietnamese ? JSON.parse(messageSettingData[0].vietnamese).product : JSON.parse(messageSettingData[0].english).product)}</a>
                </li>
                <li>
                  <a href="/postlist">{messageSettingData && (isVietnamese ? JSON.parse(messageSettingData[0].vietnamese).promotion : JSON.parse(messageSettingData[0].english).promotion)}</a>
                </li>
              </div>
            </ul>
          </div>
          {/* hinh logo */}
          <div className="col-5 col-lg-2 text-center">
            <a href="/">
              <img src={"/images/TKH.svg"} width={55} height={55} />
            </a>
          </div>
          {/* menu phai */}
          <div className="col justify-content-end">
            <ul className="menu justify-content-end">
              <div
                className={
                  isToggle
                    ? "d-lg-block mobiMenuR"
                    : "d-none d-lg-block mobiMenuR"
                }
              >
                <li>
                  <a href="/orderlist">{messageSettingData && (isVietnamese ? JSON.parse(messageSettingData[0].vietnamese).order : JSON.parse(messageSettingData[0].english).order)}</a>
                </li>
                <li>
                  <a href="/loyalty">{messageSettingData && (isVietnamese ? JSON.parse(messageSettingData[0].vietnamese).loyalty : JSON.parse(messageSettingData[0].english).loyalty)}</a>
                </li>
                {userInfoData ? (
                  <li>
                    <a onClick={handleOpen}>
                      <HiOutlineUser />
                      {userInfoData.data.display_name}
                      <span className="material-symbols-outlined text-size-1">arrow_drop_down</span>
                    </a>
                    <ul className="menuDrop">
                      <li>
                        <a>{userInfoData.data.membership}</a>
                      </li>
                      <li>
                        <a>Điểm tích luỹ: {userInfoData.data.points}</a>
                      </li>
                      <li>
                        <a onClick={logoutHandler}>{messageSettingData && (isVietnamese ? JSON.parse(messageSettingData[0].vietnamese).logout : JSON.parse(messageSettingData[0].english).logout)}</a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li>
                    <a href="/login">{messageSettingData && (isVietnamese ? JSON.parse(messageSettingData[0].vietnamese).login : JSON.parse(messageSettingData[0].english).login)}</a>
                  </li>
                )}
                <li>
                  {isVietnamese && (
                    <a onClick={() => languageHandler()}>
                    <img
                      width="20"
                      height="20"
                      src={"/images/vi.svg"}
                    />
                    </a>
                  )}
                  {!isVietnamese && (
                    <a onClick={() => languageHandler()}>
                    <img
                      width="20"
                      height="20"
                      src={"/images/eng.svg"}
                    />
                    </a>
                  )}
                </li>
              </div>
              <li style={{ position: "relative" }}>
                <a href="/cart">
                  <HiOutlineShoppingBag className="iconMenu" />
                  {cart.cartItemsData.length > 0 ? (
                    <div className="noti">{cart.cartItemsData.length}</div>
                  ) : (
                    ""
                  )}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
