import axios from "axios";
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_WITH_ORDER_FAIL,
  USER_REGISTER_WITH_ORDER_REQUEST,
  USER_REGISTER_WITH_ORDER_SUCCESS,
  USER_INIT_PASSWORD_WITH_OTP_REQUEST,
  USER_INIT_PASSWORD_WITH_OTP_SUCCESS,
  USER_INIT_PASSWORD_WITH_OTP_FAIL,
  GET_OTP_RESET_PASS_FAIL,
  USER_RESET_PASSWORD_WITH_OTP_REQUEST,
  USER_RESET_PASSWORD_WITH_OTP_SUCCESS,
  USER_RESET_PASSWORD_WITH_OTP_FAIL,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  CUSTOMER_POINTS_AND_MEMBERSHIP_SUCCESS,
  GUEST_INFO_SUCCESS,
  CHECK_OTP_TO_ORDER_REQUEST,
  CHECK_OTP_TO_ORDER_SUCCESS,
  CHECK_OTP_TO_ORDER_FAIL,
  CUSTOMER_LIST_POINT_REQUEST,
  CUSTOMER_LIST_POINT_SUCCESS,
  CUSTOMER_LIST_POINT_FAIL,
} from "../constants/userConstants";
import {
  CMS_URL,
} from '../constants/webConstants'
import { useSelector } from "react-redux";

export const register = (username) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/kojiesan/v2/register/` + username,
      //config
    );

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
      error.response && error.response.data
        ? error.response.data.message
        : "Lỗi không xác định";
    dispatch({
      type: USER_REGISTER_FAIL,
      payload: message,
    });
  }
};

export const registerWithOrder = (username, pass) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_WITH_ORDER_REQUEST,
    });

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/kojiesan/v2/registerwithorder/` +
        username +
        "/" +
        pass
      //config
    );

    dispatch({
      type: USER_REGISTER_WITH_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
      error.response && error.response.data
        ? error.response.data.message
        : "Lỗi không xác định";
    dispatch({
      type: USER_REGISTER_WITH_ORDER_FAIL,
      payload: message,
    });
  }
};

export const initPasswordWithOTP =
  (username, otp, pass, confirm_pass) => async (dispatch) => {
    try {
      dispatch({
        type: USER_INIT_PASSWORD_WITH_OTP_REQUEST,
      });

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };

      const { data } = await axios.post(
        `${CMS_URL}/wp-json/kojiesan/v2/initpass/` + username,
        { otp, pass, confirm_pass }
        //config
      );

      dispatch({
        type: USER_INIT_PASSWORD_WITH_OTP_SUCCESS,
        payload: data,
      });

      //Sau khi khởi tạo mật khẩu thành công sẽ tự động gọi hàm login để đăng nhập
      dispatch(login(username, pass));
    } catch (error) {
      //lỗi khi api trả về status=500
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Lỗi không xác định";
      dispatch({
        type: USER_INIT_PASSWORD_WITH_OTP_FAIL,
        payload: message,
      });
    }
  };

  export const getOTPResetPass = (username, history) => async (dispatch) => {
    try {
  
      const { data } = await axios.get(
        `${CMS_URL}/wp-json/kojiesan/v2/getotpresetpass/` + username
        //config
      );
  
    } catch (error) {
      //lỗi khi api trả về status=500
      const message =
      error.response && error.response.data
        ? error.response.data.message
        : "Lỗi không xác định"
      dispatch({
        type: GET_OTP_RESET_PASS_FAIL,
        payload: message,
      });
    }
  }


export const resetPasswordWithOTP =
  (username, otp, new_pass) => async (dispatch) => {
    try {
      dispatch({
        type: USER_RESET_PASSWORD_WITH_OTP_REQUEST,
      });

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };

      const { data } = await axios.post(
        `${CMS_URL}/wp-json/kojiesan/v2/resetpass/` + username,
        { otp, new_pass }
        //config
      );

      dispatch({
        type: USER_RESET_PASSWORD_WITH_OTP_SUCCESS,
        payload: data,
      });

      //Sau khi khởi tạo mật khẩu thành công sẽ tự động gọi hàm login để đăng nhập
      dispatch(login(username, new_pass));
    } catch (error) {
      //lỗi khi api trả về status=500
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Lỗi không xác định";
      dispatch({
        type: USER_RESET_PASSWORD_WITH_OTP_FAIL,
        payload: message,
      });
    }
  };

export const login = (username, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    // const {
    //   cartFromStore: { guestInfoFromStore },
    // } = getState();

    // Selector
    const guestInfoFromStore = state => state.guestInfoFromStore;

    const token = btoa(username + ":" + password);

    const config = {
      headers: {
        Authorization: "Basic " + token,
      },
    };

    const { data } = await axios.post(
      `${CMS_URL}/wp-json/kojiesan/v2/login`,
      { username, password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfoFromStorage", JSON.stringify(data));
    //Nếu không phải là login sau khi nhập OTP TẠO TÀI KHOẢN TỪ ĐƠN HÀNG thì remove guestInfoFromStorage, cartItemsFromStorage
    if (!guestInfoFromStore) {
      localStorage.removeItem("guestInfoFromStorage");
      localStorage.removeItem("cartItemsFromStorage");
    }
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
      error.response && error.response.data
        ? error.response.data.message
        : "Lỗi không xác định";
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: message,
    });
  }
};

export const getCustomerPointsAndMembership = (userid) => async (dispatch) => {
  try {
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/kojiesan/v2/getcustomerpointsandmembership/`+
        userid +
        "/"
      //config
    );
    dispatch({
      type: CUSTOMER_POINTS_AND_MEMBERSHIP_SUCCESS,
      payload: data,
    });
    localStorage.setItem(
      "customerPointsAndMembershipFromStorage",
      JSON.stringify(data)
    );
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
      error.response && error.response.data
        ? error.response.data.message
        : "Lỗi không xác định";
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfoFromStorage");
  localStorage.removeItem("guestInfoFromStorage");
  localStorage.removeItem("cartItemsFromStorage");
  localStorage.removeItem("shippingAddressFromStorage");
  localStorage.removeItem("paymentMethodFromStorage");
  localStorage.removeItem("orderNoteFromStorage");
  localStorage.removeItem("appSettingsFromStorage");
  localStorage.removeItem("messageSettingsFromStorage");
  localStorage.removeItem("currentLanguageFromStorage");
  localStorage.removeItem("customerPointsAndMembershipFromStorage");
  dispatch({ type: USER_LOGOUT });
  document.location.href = "/login";
};

export const getUserDetails = (username) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    //lấy giá trị userLogin được định nghĩa trong store.js-> initialState
    const {
      userInfoFromStore: { userInfoData },
    } = getState();

    const config = {
      headers: {
        accept: "application/json",
        //Access-Control-Allow-Origin: '*',
        token: `Bearer ${userInfoData.data.token}`,
      },
    };

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/kojiesan/v2/username/${username}`,
      config
    );

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
      error.response && error.response.data
        ? error.response.data.message
        : "Lỗi không xác định";
    //nếu token hết hạn hoặc token không hợp lệ thì gọi hàm logout (xoá đối tượng userInfoFromStorage)
    if (
      message === "expired_token" ||
      message === "signature_verification_failed"
    ) {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateUserProfile =
  (username, display_name, birthday, shipping_address_1, email, showChangePassword, old_pass, new_pass) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      });

      const {
        userInfoFromStore: { userInfoData },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${userInfoData.data.token}`,
        },
      };

      const { data } = await axios.post(
        `${CMS_URL}/wp-json/kojiesan/v2/updateuserprofile`,
        {
          username,
          display_name,
          birthday,
          shipping_address_1,
          email,
          showChangePassword,
          old_pass,
          new_pass,
        },
        config
      );

      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
      if(!showChangePassword){//KH không cập nhật mật khẩu -> Lấy giá trị basic_auth trong local storage gán vào data trả về
        const basic_auth = localStorage.getItem('userInfoFromStorage') ? JSON.parse(localStorage.getItem('userInfoFromStorage')).data.basic_auth: ''
        data.data.basic_auth = basic_auth
      }
      localStorage.setItem("userInfoFromStorage", JSON.stringify(data));
    } catch (error) {
      //lỗi khi api trả về status=500
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Lỗi không xác định";
      //nếu token hết hạn hoặc token không hợp lệ thì gọi hàm logout (xoá đối tượng userInfoFromStorage)
      if (
        message === "expired_token" ||
        message === "signature_verification_failed"
      ) {
        dispatch(logout());
      }

      /*
    khi lỗi thì gọi case USER_UPDATE_PROFILE_FAIL, được định nghĩa trong userReducer.js -> userUpdateProfileReducer
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    -> sau khi hàm dispatch bên dưới chạy thì đối tượng error sẽ chứa thông tin message
*/
      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload: message,
      });
    }
  };

  export const saveGuestInfo = (data) => (dispatch) => {
    dispatch({
      type: GUEST_INFO_SUCCESS,
      payload: data,
    })
    localStorage.setItem('guestInfoFromStorage', JSON.stringify({ display_name: data.display_name, phone: data.phone, pass: data.pass}))
  }

  export const checkOrderOTP = (username, otp, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CHECK_OTP_TO_ORDER_REQUEST,
      });
  
      // const {
      //   cartFromStore: { guestInfoFromStore },
      // } = getState();

      // const { guestInfoData }  = useSelector((state) => state.guestInfoFromStore);

      // Selector
      // const guestInfoFromStore = state => state.guestInfoFromStore;
  
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
  
      const { data } = await axios.get(
        `${CMS_URL}/wp-json/kojiesan/v2/checkorderotp/` + username + "/" + otp
        //config
      );
  
      const callback_data = { display_name: JSON.parse(localStorage.getItem('guestInfoFromStorage')).display_name, phone: JSON.parse(localStorage.getItem('guestInfoFromStorage')).phone, pass: JSON.parse(localStorage.getItem('guestInfoFromStorage')).pass, order_otp_valid:true };
  
      //Kiểm tra OTP để tạo đơn hàng hợp lệ thì thêm trường order_otp_valid=true vào guestInfoFromStorage
      localStorage.setItem('guestInfoFromStorage', JSON.stringify(callback_data))
  
      dispatch({
        type: CHECK_OTP_TO_ORDER_SUCCESS,
        payload: callback_data,
      });
  
      //Kiểm tra xem KH có muốn đăng ký tài khoản không guestInfoFromStore.pass!==""
      //Nếu có thì gọi hàm đăng ký
      dispatch(registerWithOrder(username, JSON.parse(localStorage.getItem('guestInfoFromStorage')).pass))
  
      //Sau đó nếu đăng ký thành công thì gọi hàm login để khởi tạo giá trị userInfoData
      dispatch(login(username, JSON.parse(localStorage.getItem('guestInfoFromStorage')).pass));
  
      //Sau đó gọi hiển thị trang đặt hàng
      //Nếu KH có chọn tạo tài khoản từ màn hình đặt hàng thì hiển thị thông báo tạo tài khoản thành công
      if(JSON.parse(localStorage.getItem('guestInfoFromStorage')).pass){
        history.push("/placeorder?message=create_user_success")
      }else{
        history.push("/placeorder")
      }
      
  
    } catch (error) {
      //lỗi khi api trả về status=500
      const message =
      error.response && error.response.data
        ? error.response.data.message
        : "Lỗi không xác định"
      dispatch({//dispatch to cartReducer.js -> case CHECK_OTP_TO_ORDER_FAIL
        type: CHECK_OTP_TO_ORDER_FAIL,
        payload: message,
      });
    }
  }

  export const getCustomerListPoint = (userid) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CUSTOMER_LIST_POINT_REQUEST,
      });
  
      //lấy giá trị userLogin được định nghĩa trong store.js-> initialState
      const {
        userInfoFromStore: { userInfoData },
      } = getState();
  
      const config = {
        headers: {
          accept: "application/json",
          //Access-Control-Allow-Origin: '*',
          token: `Bearer ${userInfoData.data.token}`,
        },
      };
  
      const { data } = await axios.get(
        `${CMS_URL}/wp-json/kojiesan/v2/getcustomerlistpoint/${userid}`,
        config
      );
  
      dispatch({
        type: CUSTOMER_LIST_POINT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      //lỗi khi api trả về status=500
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Lỗi không xác định";
      //nếu token hết hạn hoặc token không hợp lệ thì gọi hàm logout (xoá đối tượng userInfoFromStorage)
      if (
        message === "expired_token" ||
        message === "signature_verification_failed"
      ) {
        dispatch(logout());
      }
      dispatch({
        type: CUSTOMER_LIST_POINT_FAIL,
        payload: message,
      });
    }
  }