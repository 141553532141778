import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup} from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  getPostDetailsFromAction,
} from '../actions/postActions'

const PromotionDetailScreen = ({ match }) => {
  const dispatch = useDispatch()

  const postDetailsFromScreen = useSelector((state) => state.postDetailsFromStore)
  const { loading, error, postDetailsData } = postDetailsFromScreen

  useEffect(() => {
      dispatch(getPostDetailsFromAction(match.params.id))
  }, [dispatch, match])

  return (
    <>
      {loading ? (
        <div className="loading-full">
          <Loader />
        </div>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
        <div className='container mt-5 mb-5'>
          <div className='d-flex row'>
            <div className='col-md-12 bg-white text-justify'>
              <ListGroup variant='flush'>
                <ListGroup.Item style={{backgroundColor: '#fef5f3'}}>
                  <h3>{postDetailsData.title?postDetailsData.title.rendered:''}</h3>
                </ListGroup.Item>
                {/* <ListGroup.Item> */}
                  <div className='px-3' dangerouslySetInnerHTML={{ __html: postDetailsData.content?postDetailsData.content.rendered:'' }} />
                {/* </ListGroup.Item> */}
              </ListGroup>
            </div>
          </div>
        </div>
        </>
      )}
    </>
  )
}

export default PromotionDetailScreen
