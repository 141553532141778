import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { CMS_URL } from "../constants/webConstants";
import { Container } from "react-bootstrap";
import ReactCompareImage from "react-compare-image"

const Banner = ({ banner_app_type, banner_type }) => {
  const navigationStyle = {
    color: "#ffffff", // Màu của nút Navigation trong file này
  };
  //get banners
  const [banners, setBanners] = useState([]);

  const [desText, setdesText] = useState(0);

  const dispatch = useDispatch();

  const fetchBanners = (id) => async () => {
    try {
      //Lấy danh sách banner theo banner_app_type và banner_type
      const { data } = await axios.get(
        `${CMS_URL}/wp-json/kojiesan/v2/banner?banner_app_type=${banner_app_type}&banner_type=${banner_type}&banner_active=1`,
        {
          headers: {
            accept: "application/json",
          },
        }
      );
      setBanners(data);
    } catch (error) {
      //lỗi khi api trả về status=500
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Lỗi không xác định";
    }
  };

  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch]);

  const bannerList = banners.map((banner, key) => (
    <SwiperSlide key={key}>
      {/* Nếu banner là hình ảnh có deeplink dẫn vào chi tiết bài post */}
      {!banner.banner_is_video && banner.banner_deeplink !== '' && (
        <a href={`${banner.banner_deeplink}`}>
          <img src={banner.banner_image_url} className="imgHomeBanner" />
        </a>
      )}
      {/* Nếu banner là hình ảnh không có deeplink dẫn vào chi tiết bài post */}
      {!banner.banner_is_video && banner.banner_deeplink === '' && (
        <img src={banner.banner_image_url} className="imgHomeBanner" />
      )}
      {/* Nếu banner là video có deeplink dẫn vào chi tiết bài post */}
      {banner.banner_is_video && banner.banner_deeplink !== '' && (
        <a href={`${banner.banner_deeplink}`}>
          <video
            autoPlay
            playsInline
            loop
            muted
            width="100%"
            height="100%"
            src={banner.banner_image_url}
            type="video/mp4"
          ></video>
        </a>
      )}
      {/* Nếu banner là video không có deeplink dẫn vào chi tiết bài post */}
      {banner.banner_is_video && banner.banner_deeplink === '' && (
        <video
          autoPlay
          playsInline
          loop
          muted
          width="100%"
          height="100%"
          src={banner.banner_image_url}
          type="video/mp4"
        ></video>
      )}
    </SwiperSlide>
  ));

  return (
    <>
      {/* Đây là banner trên trang chủ */}
      {banner_type === "banner_home" && (
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={20}
          loop={true} // Lặp lại các slide
          navigation
          autoplay={{
            delay: 45000, // Thiết lập thời gian tự động chuyển slide là 45 giây
            disableOnInteraction: false,
          }}
          slidesPerView={1}
        >
          {bannerList}
        </Swiper>
      )}
      {/* Đây là banner video trên trang khuyến mãi và kích thước màn hình >= MD */}
      {banner_type === "banner_product" && window.innerWidth >= 768 && (
        <>
          {banners.map((banner, key) => (
            <video
            autoPlay
            playsInline
            loop
            muted
            width="100%"
            height="100%"
            src={banner.banner_image_url}
            type="video/mp4"
          ></video> 
          ))}
        </>
      )}
      {/* Đây là banner video trên trang khuyến mãi và kích thước màn hình < MD */}
      {banner_type === "banner_product" && window.innerWidth < 768 && (
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={20}
          loop={true} // Lặp lại các slide
          navigation
          autoplay={{
            delay: 15000, // Thiết lập thời gian tự động chuyển slide là 15 giây
            disableOnInteraction: false,
          }}
          slidesPerView={1}
        >
          <div className="swiper-button-prev" style={navigationStyle}></div>
          <div className="swiper-button-next" style={navigationStyle}></div>
          {banners.map((banner, key) => (
            <SwiperSlide key={key}>
              <video
                autoPlay
                playsInline
                loop
                muted
                width="100%"
                height="100%"
                src={banner.banner_image_url}
                type="video/mp4"
              ></video> 
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {/* Đây là banner review trên trang chủ */}
      {banner_type === "review" && (
        <Container className="box" style={{ paddingBottom: 100 }}>
          {/* <h1 className="titleSlide h2 mb-3">CẢM NHẬN CỦA KHÁCH HÀNG</h1> */}
          <div className="reviewDes">
            <p>Bạn thấy sản phẩm thế nào?</p>
          </div>
          <div className="reviewDes">
            <div
              dangerouslySetInnerHTML={{
                __html: banners[desText]?.banner_content_short
                  ? banners[desText]?.banner_content_short
                  : "Đang nhập...",
              }}
            />
          </div>
          <Swiper
            modules={[Navigation, Autoplay]}
            navigation
            autoplay={{
              delay: 5000, // Thiết lập thời gian tự động chuyển slide là 5 giây
              disableOnInteraction: true,
            }}
            centeredSlides={true}
            breakpoints={{
              320: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
            onRealIndexChange={(element) => setdesText(element.realIndex)}
          >
            {banners.map((banner, key) => {
              return (
                <SwiperSlide key={key} className="review">
                  <img
                    src={banner.banner_image_url}
                    alt="review"
                    className="imgHomeBanner"
                    data={key}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
      )}
      {/* Đây là phần Intro Before/After trên trang chủ */}
      {banner_type === "intro" && (
        <>
        {banners.map((banner, key) => (
          <>
            <div className="row m-0 p-2 bg-white border rounded justify-content-center">
              <div className="col-md-4 mt-1 custom-width-80">
                {/* BeforeAfterImage */}
                <ReactCompareImage leftImage={banner.banner_image_url} rightImage={banner.banner_deeplink} />
              </div>
              <div className="col-md-4 mt-1 p-3">
                  <p className="text-justify para mb-0"><div dangerouslySetInnerHTML={{ __html: banner.banner_content_full?banner.banner_content_full:'' }} /></p>
              </div>
            </div>
          </>
          ))}
        </>
      )}
      {/* Đây là phần thông tin hội viên trong trang loyalty */}
      {banner_type === "loyalty" && (
        <>
        {banners.map((banner, key) => (
          <img src={banner.banner_image_url} />
          ))}
        </>
      )}
    </>
  );
};

export default Banner;
