import React from "react";

const Product = ({ type, product }) => {
  return (
    <>
    {/* Nếu gọi từ màn hình home */}
    {type === 'home' && (
      <a className="text-decoration-none" href={"/product/" + product.slug} style={{ textDecoration: "none" }}>
        <div className="productBox">
          <div className="productBoxImg">
            <img src={product.images[0].src} alt={product.name} />
            {product.sale_price > 0 && (
                                <div class="sale-icon"></div>
                              )}
          </div>
          <h2 className="titleProduct">{product.name}</h2>
          {/* <Rating
            value={product.average_rating}
            text={`${product.rating_count} reviews`}
            color={'#ea0429'}
          /> */}
          <a className="btn mt-3" href={"/cart/" + product.id + "?qty=1"}>
            Giỏ hàng -{" "}
            {parseInt(product.price).toLocaleString("vi", {
              style: "currency",
              currency: "VND",
            })}
          </a>
        </div>
      </a>
    )}
    {/* Nếu gọi từ màn hình danh sách sản phẩm */}
    {type === 'list' && (
      <a className="text-decoration-none" href={"/product/" + product.slug} style={{ textDecoration: "none" }}>
        <div className="productBox">
          <div className="productBoxImg">
            <img src={product.images[0].src} alt={product.name} />
            {product.sale_price > 0 && (
                                <div class="sale-icon"></div>
                              )}
          </div>
          <h2 className="titleProduct">{product.name}</h2>
          {/* <Rating
            value={product.average_rating}
            text={`${product.rating_count} reviews`}
            color={'#ea0429'}
          /> */}
          <a className="btn mt-3" href={"/cart/" + product.id + "?qty=1"}>
            Giỏ hàng -{" "}
            {parseInt(product.price).toLocaleString("vi", {
              style: "currency",
              currency: "VND",
            })}
          </a>
        </div>
      </a>
    )}
   </>
  );
};

export default Product;
