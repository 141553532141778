import React, {useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import CheckoutSteps from '../components/CheckoutSteps'
import { createOrder, getCouponDetailsByCode } from '../actions/orderActions'
import { getCustomerPointsAndMembership } from '../actions/userActions'
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import { USER_DETAILS_RESET } from '../constants/userConstants'
//Import LanguageContext được export từ App.js
import { LanguageContext } from '../App'
import Loader from '../components/Loader'


  const PlaceOrderScreen = ({ history, location }) => {

  /*CHÚ Ý QUAN TRỌNG
  - Sau khi login thành công thì lưu thông tin user vào đối tượng userInfoData (ở hàm userReducer.js -> userLoginReducer)
  - Đối tượng này được định nghĩa trong store.js
  - Đối tượng này sẽ mất đi khi chuyển/refesh trang 
  -> Để sử dụng đối tượng userInfoData trong các màn hình mà không cần dispatch lại API userLogin 
  -> Thì trong store.js sử dụng Local storage để lưu đối tượng userInfoFromStorage
      const userInfoFromStorage = localStorage.getItem('userInfoFromStorage')
      ? JSON.parse(localStorage.getItem('userInfoFromStorage'))
      : null
  - Sau đó hàm initialState sẽ gán đối tượng Local Storage userInfoFromStorage vào các đối tượng trong userInfoData
    const initialState = {
      cartFromStore: {
        cartItemsData: cartItemsFromStorage,
      },
      userInfoFromStore: { userInfoData: userInfoFromStorage },
      messageSettingsFromStore: messageSettingsFromStorage,
    }
  - Đối tượng này sẽ được sử dụng để kiểm tra user đã đăng nhập chưa và được sử dụng trong các components dùng chung như Header.js
  - Khác với đối tượng userInfoData (ở hàm userReducer.js -> userRegisterReducer) được dùng trong RegisterScreen
*/
  const userLoginFromScreen = useSelector((state) => state.userInfoFromStore)
  const { userInfoData } = userLoginFromScreen

  //Lấy thông tin shipping_fee, danh sách điểm có thể sử dụng redeemable_point từ appSettingsFromStore
  const appSettingsFromScreen = useSelector((state) => state.appSettingsFromStore)
  const { shipping_fee, redeemable_point } = appSettingsFromScreen

  const cart = useSelector((state) => state.cartFromStore)

  const guestInfoFromScreen = useSelector((state) => state.guestInfoFromStore);
  const { guestInfoData } = guestInfoFromScreen

  /* Nếu KH đã đăng nhập thì mỗi 1 phút component AutoRequestServer sẽ gọi request đến backend
    để lấy điểm tích luỹ-hạng thành viên và điểm đã sử dụng đưa vào userPointsAndMembershipFromStore */
  const customerPointsAndMembershipFromScreen = useSelector((state) => state.customerPointsAndMembershipFromStore)
  const {points,redeempoints, success:successPointsAndMembership} = customerPointsAndMembershipFromScreen

  //Lấy thông báo tạo tài khoản thành công hay thất bại tù màn hình OrderOTP
  const searchParams = new URLSearchParams(location.search);
  const URLMessage = searchParams.get('message');
  
  //Lấy thông tin mã khuyến mãi
  const couponDetailsFromScreen = useSelector((state) => state.couponDetailsFromStore);
  const { couponDetailData, success:successCoupon, error:errorCoupon } = couponDetailsFromScreen

  //Lấy ngôn ngữ được chọn có phải tiếng Việt không từ biến dùng chung isVietnamese trong LanguageContext được khai báo trong App.js
  const { isVietnamese, setIsVietnamese } = useContext(LanguageContext);
  //messageSettingData chứa thông tin message như Trang chủ, Sản phẩm... cho các menu và label
  const messageSettingsFromScreen = useSelector((state) => state.messageSettingsFromStore);
  const { messageSettingData } = messageSettingsFromScreen;

  // Hàm xử lý khi radio Nhập mã khuyến mãi/Sử dụng điểm thay đổi
  const handleRadioButtonChange = (event) => {
    // if(event.target.value === 'yes'){//Nếu chọn radio Nhập mã khuyến mãi
    //   setShowCouponTextBox(true)
    //   setShowRedeemPoinCombobox(false)
    // }
    setShowCouponTextBox(event.target.value === 'yes')//Nếu chọn radio Nhập mã khuyến mãi thì hiển thị textbox nhập mã
    setIsApplyRedeem(event.target.value === 'yes')//Nếu chọn radio Nhập mã khuyến mãi thì hiển thị nút đặt hàng
    setRedeemError(!event.target.value === 'yes')//Nếu chọn radio Nhập mã khuyến mãi thì bỏ thông báo lỗi của sử dụng điểm
    // setShowRedeemPoinCombobox(event.target.value === 'no')//Nếu chọn radio Sử dụng điểm
  };
  
  //Begin-Phần mã khuyến mãi
  //Hiện/Ẩn textbox nhập mã khuyến mãi
  const [showCouponTextBox, setShowCouponTextBox] = useState(true)
  //Mã khuyến mãi
  const [couponCode, setCouponCode] = useState('')
  //Số tiền được giảm từ mã khuyến mãi
  const [couponDiscountAmount, setCouponDiscountAmount] = useState('')
  //Có phải coupon free_ship không
  const [isFreeShippingCoupon, setIsFreeShippingCoupon] = useState(false)

  const applyCouponHandler = (couponCode) => {
    dispatch(getCouponDetailsByCode(couponCode))
  }

  const unapplyCouponHandler = () => {
    //reload lại trang
    window.location.reload()
  }
  //End-Phần mã khuyến mãi

  //Begin-Phần đổi điểm
  //Kiểm tra đã nhấn nút áp dụng đổi điểm chưa
  //Để đổi hiển thị số tiền giảm và active nút đặt hàng
  const [isApplyRedeem, setIsApplyRedeem] = useState(true)
  // Chuyển chuỗi JSON redeemable_point thành đối tượng JavaScript redeemablePointData
  const redeemablePointData = JSON.parse(redeemable_point)
  //Kiểm tra có đủ điều kiện sử dụng điểm không
  const [redeemError, setRedeemError] = useState(false)
  //Số tiền được giảm từ sử dụng điểm
  const [redeemMoney, setRedeemMoney] = useState(0)
  // Hàm xử lý khi giá trị của combobox chọn số tiền thay đổi
  const handleRedeemPointSelectChange = (event) => {
    setRedeemMoney(event.target.value)
    setIsApplyRedeem(false)//Không hiển thị số tiền giảm và disable nút đặt hàng
  };
  //Lấy số điểm được chọn của combox sử dụng điểm
  const needRedeemPoint = Object.keys(redeemablePointData).find(
    (key) => redeemablePointData[key] === redeemMoney
  );

  //Điểm tra đã nhấn nút đặt hàng hay chưa
  const [isSubmitOrder, setIsSubmitOrder] = useState(true)

  const applyRedeemPointHandler = () => {
    dispatch(getCustomerPointsAndMembership(userInfoData.data.userid))
    if(successPointsAndMembership){
      if((needRedeemPoint > (points - redeempoints))){ //Điểm muốn đổi > Điểm còn lại (points - redeempoints)-> Báo lỗi không đủ điểm
        setRedeemError(true)
        setIsApplyRedeem(false)//Ẩn số tiền giảm và disable nút đặt hàng
      }else{
        setRedeemError(false)
        setIsApplyRedeem(true)//Hiển thị số tiền giảm và active nút đặt hàng
      }
    }
  }

 //End-Phần đổi điểm

  const dispatch = useDispatch()

  //Nếu KH chưa đăng nhập và chưa xác thực Order OTP thành công 
  //-> redirect về màn hình shipping
  if(!userInfoData && !guestInfoData.order_otp_valid){
    history.push('/shipping')
  }
  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  cart.itemsPrice = cart.cartItemsData.reduce((acc, item) => acc + item.price * item.quantity, 0)
  cart.shippingPrice = 0
  cart.taxPrice = addDecimals(0)

  //Thành tiền chưa trừ couponDiscountAmount
  cart.subTotalPrice = (
    Number(cart.itemsPrice) +
    //Nếu có coupon free_shipping thì phí shipping=0 ngược lại phí shipping= shipping_fee (lấy từ AppSetting)
    Number(shipping_fee) +
    Number(cart.taxPrice)
  )

  //Tổng tiền sau khi trừ couponDiscountAmount, redeemMoney
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    //Nếu có coupon free_shipping thì phí shipping=0 ngược lại phí shipping= shipping_fee (lấy từ AppSetting)
    Number(shipping_fee) +
    Number(cart.taxPrice) -
    Number(couponDiscountAmount) -
    //Nếu không phải hiển thị textbox nhập mã khuyến mãi, đã nhấn nút áp dụng điểm điểm hợp lệ và không lỗi 
    //Thì số tiền giảm = redeemMoney ngược lại số tiền giảm = 0
    Number(!showCouponTextBox && isApplyRedeem && !redeemError?redeemMoney:0)
  )

  const orderCreate = useSelector((state) => state.orderCreateFromStore)
  const { order, success, error:errorOrderCreate } = orderCreate

  useEffect(() => {
    //Enable lại nút đặt hàng
    setIsSubmitOrder(false)
    if (success) {
      history.push(`/order/${order.id}`)
      dispatch({ type: USER_DETAILS_RESET })
      dispatch({ type: ORDER_CREATE_RESET })
    }
    //Nếu KH nhấn nút áp dụng coupon thì gán giá trị cho số tiền được giảm couponDiscountAmount và biến kiểm tra có phải là coupon freeship không isFreeShippingCoupon
    if(successCoupon){
      //Nếu là coupon freeship và số tiền sản phẩm >= số tiền tối thiểu của đơn hàng (couponDetailData.data.minimum_amount) thì số tiền giảm là shipping_fee (lấy từ AppSetting)
      //Nếu là coupon freeship và số tiền sản phẩm < số tiền tối thiểu của đơn hàng (couponDetailData.data.minimum_amount) thì số tiền giảm là 0
      if(couponDetailData.data.free_shipping){
        setCouponDiscountAmount(cart.itemsPrice >= couponDetailData.data.minimum_amount ? shipping_fee:0)
        setIsFreeShippingCoupon(couponDetailData.data.free_shipping)
      }else{//Nếu không phải coupon freeship thì số tiền giảm là couponDetailData.data.amount (là loại coupon giảm xxx cho tổng đơn hàng (fixed_cart))
        //Nếu số tiền sản phẩm >= số tiền tối thiểu của đơn hàng (couponDetailData.data.minimum_amount) thì số tiền giảm là couponDetailData.data.amount
        //Nếu số tiền sản phẩm < số tiền tối thiểu của đơn hàng (couponDetailData.data.minimum_amount) thì số tiền giảm là 0
        setCouponDiscountAmount(!couponDetailData.data.free_shipping && cart.itemsPrice >= couponDetailData.data.minimum_amount ?couponDetailData.data.amount:0)
      }
    }
    // eslint-disable-next-line
  }, [history, success, successCoupon])

//Duyệt mảng sản phẩm trong cart.cartItemsFromStorage và gán vào đối tượng line_items
const [line_items, setLine_items] = useState(
  cart.cartItemsData.map(({ slug, product_id, quantity }) => ({ slug, product_id,  quantity}))
)

  const placeOrderHandler = () => {
      //KH đã nhấn nút đặt hàng
      setIsSubmitOrder(true)
      //Nếu KH có nhập coupon hợp lệ
      if(couponCode){
        dispatch(
          createOrder({
            //Nếu KH đã đăng nhập -> tạo đơn hàng dùng Wordpress Customer -> lấy userid từ userInfoData
            //Nếu KH chưa đăng ký và chọn đăng ký -> đăng ký thành công sẽ lấy userid từ userInfoData
            //Ngược lại nếu KH chưa đăng ký -> tạo đơn hàng dùng Woocommerce Customer/Wordpress Guest (customer_id=0)
            customer_id: userInfoData?userInfoData.data.userid:0,
            // username: userInfoData.data.username,
            status: 'on-hold',
            billing: {
                      "first_name": userInfoData?userInfoData.data.display_name:guestInfoData.display_name,
                      "address_1": cart.shippingAddressData,
                      "phone": userInfoData?userInfoData.data.username:guestInfoData.phone,              },
            shipping_lines: [//phi ship
            {
                "method_id": "flat_rate",
                //Nếu là coupon freeship và số tiền sản phẩm >= số tiền tối thiểu của đơn hàng (couponDetailData.data.minimum_amount) thì số tiền giảm là shipping_fee (lấy từ AppSetting)
                //Nếu là coupon freeship và số tiền sản phẩm < số tiền tối thiểu của đơn hàng (couponDetailData.data.minimum_amount) thì số tiền giảm là 0
                "total": couponDetailData && couponDetailData.data && couponDetailData.data.free_shipping && cart.itemsPrice >= couponDetailData.data.minimum_amount ? "0":shipping_fee,  
            }],
            "coupon_lines": [//coupon data
            {
                "code": couponCode?couponCode:"",
                "description": couponDetailData && couponDetailData.data && couponDetailData.data.description?couponDetailData.data.description:"", 
            }
            ],
            line_items: line_items,
            customer_note: cart.orderNoteData ,
            payment_method: cart.paymentMethodData
          })
        )
      }else if(redeemMoney !==0){//Nếu KH nhập điểm hợp lệ
        dispatch(
          createOrder({
            //Nếu KH đã đăng nhập -> tạo đơn hàng dùng Wordpress Customer -> lấy userid từ userInfoData
            //Nếu KH chưa đăng ký và chọn đăng ký -> đăng ký thành công sẽ lấy userid từ userInfoData
            //Ngược lại nếu KH chưa đăng ký -> tạo đơn hàng dùng Woocommerce Customer/Wordpress Guest (customer_id=0)
            customer_id: userInfoData?userInfoData.data.userid:0,
            // username: userInfoData.data.username,
            status: 'on-hold',
            billing: {
                      "first_name": userInfoData?userInfoData.data.display_name:guestInfoData.display_name,
                      "address_1": cart.shippingAddressData,
                      "phone": userInfoData?userInfoData.data.username:guestInfoData.phone,              },
            shipping_lines: [//phi ship
            {
                "method_id": "flat_rate",
                //Nếu là coupon freeship và số tiền sản phẩm >= số tiền tối thiểu của đơn hàng (couponDetailData.data.minimum_amount) thì số tiền giảm là shipping_fee (lấy từ AppSetting)
                //Nếu là coupon freeship và số tiền sản phẩm < số tiền tối thiểu của đơn hàng (couponDetailData.data.minimum_amount) thì số tiền giảm là 0
                "total": couponDetailData && couponDetailData.data && couponDetailData.data.free_shipping && cart.itemsPrice >= couponDetailData.data.minimum_amount ? "0":shipping_fee,  
            }],
            line_items: line_items,
            "fee_lines": [//Số tiền giảm bằng đổi điểm
                  {
                      "name": `${needRedeemPoint}`,//Số điểm đổi
                      "total": `-${redeemMoney}`,//Số tiền giảm
                  }
              ],
            customer_note: cart.orderNoteData ,
            payment_method: cart.paymentMethodData
          })
        )
      }else{//Nếu không nhập mã coupon và điểm
        dispatch(
          createOrder({
            //Nếu KH đã đăng nhập -> tạo đơn hàng dùng Wordpress Customer -> lấy userid từ userInfoData
            //Nếu KH chưa đăng ký và chọn đăng ký -> đăng ký thành công sẽ lấy userid từ userInfoData
            //Ngược lại nếu KH chưa đăng ký -> tạo đơn hàng dùng Woocommerce Customer/Wordpress Guest (customer_id=0)
            customer_id: userInfoData?userInfoData.data.userid:0,
            // username: userInfoData.data.username,
            status: 'on-hold',
            billing: {
                      "first_name": userInfoData?userInfoData.data.display_name:guestInfoData.display_name,
                      "address_1": cart.shippingAddressData,
                      "phone": userInfoData?userInfoData.data.username:guestInfoData.phone,              },
            shipping_lines: [//phi ship
            {
                "method_id": "flat_rate",
                //Nếu là coupon freeship và số tiền sản phẩm >= số tiền tối thiểu của đơn hàng (couponDetailData.data.minimum_amount) thì số tiền giảm là shipping_fee (lấy từ AppSetting)
                //Nếu là coupon freeship và số tiền sản phẩm < số tiền tối thiểu của đơn hàng (couponDetailData.data.minimum_amount) thì số tiền giảm là 0
                "total": couponDetailData && couponDetailData.data && couponDetailData.data.free_shipping && cart.itemsPrice >= couponDetailData.data.minimum_amount ? "0":shipping_fee,  
            }],
            line_items: line_items,
            customer_note: cart.orderNoteData ,
            payment_method: cart.paymentMethodData
          })
        )
      }
    }

  return (
    <>
      {isSubmitOrder &&(
         <div className="loading-full">
          <Loader />
        </div>
          )}
      {/* <CheckoutSteps step1 step2/> */}
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            {messageSettingData && URLMessage && <Message variant="success">{isVietnamese ? JSON.parse(messageSettingData[0].vietnamese)[URLMessage]: JSON.parse(messageSettingData[0].english)[URLMessage]}</Message>}
            <ListGroup.Item>
              <h4>Thông tin giao hàng</h4>
              <p>
                <strong>Địa chỉ nhận hàng:</strong>
                {cart.shippingAddressData}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h4>Hình thức thanh toán</h4>
              {cart.paymentMethodData === 'BankTransfer' ? 'Chuyển khoản': cart.paymentMethodData === 'COD' ? 'Thanh toán khi nhận hàng':'Vui lòng chọn hình thức thanh toán'}
            </ListGroup.Item>

            <ListGroup.Item>
              <h4>Ghi chú đơn hàng</h4>
              {cart.orderNoteData}
            </ListGroup.Item>

            <ListGroup.Item>
              <h4>Danh sách sản phẩm</h4>
              {cart.cartItemsData.length === 0 ? (
                <Message>Giỏ hàng chưa có sản phẩm</Message>
              ) : (
                <ListGroup variant='flush'>
                  {cart.cartItemsData.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.slug}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.quantity} x {parseInt(item.price).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })} = {parseInt(item.quantity * item.price).toLocaleString("vi", {
                                                    style: "currency",
                                                    currency: "VND",
                                                    })}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h4>Thông tin thanh toán</h4>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tiền sản phẩm</Col>
                  <Col>{parseInt(cart.itemsPrice).toLocaleString("vi", {
                                                    style: "currency",
                                                    currency: "VND",
                                                    })}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Phí vận chuyển (tạm tính)</Col>
                  <Col>{parseInt(shipping_fee?shipping_fee:"30000").toLocaleString("vi", {
                                                    style: "currency",
                                                    currency: "VND",
                                                    })}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Thành tiền</Col>
                  <Col>{parseInt(cart.subTotalPrice).toLocaleString("vi", {
                                                    style: "currency",
                                                    currency: "VND",
                                                    })}</Col>
                </Row>
              </ListGroup.Item>
              <div>
                {userInfoData && (//KH đăng nhập mới thấy chức năng sử dụng điểm 
                  <>
                  {/* Radio button nhập mã khuyến mãi hoặc sử dụng điểm */}
                  <div className='text-center'>
                    <label>
                      <input
                        type="radio"
                        value="yes"
                        checked={showCouponTextBox}
                        onChange={handleRadioButtonChange}
                      />
                      Nhập mã khuyến mãi
                    </label>
                    <label className='ml-2'>
                      <input
                        type="radio"
                        value="no"
                        checked={!showCouponTextBox}
                        onChange={handleRadioButtonChange}
                      />
                      {/* Nếu tồn tại points/redeempoints từ AutoRequestServer->userPointsAndMembershipFromStore thì sử dụng AutoRequestServer->userPointsAndMembershipFromStore->points/redeempoints
                      Ngược lại thì sử dụng points/redeempoints từ userInfoData.data.points/redeempoints */}
                      Sử dụng điểm <span className='font-italic'>(Điểm còn lại <span className='required'>{(points?points:userInfoData.data.points) - (redeempoints?redeempoints:userInfoData.data.redeempoints)}</span>)</span>
                    </label>
                  </div>
                  </>
                )}
                
                {/* Radio nhập mã khuyến mãi */}
                {showCouponTextBox && (
                  <ListGroup.Item>
                      <Row>
                        <Form.Control
                          type='coupon'
                          value={couponCode}
                          placeholder='Nhập mã khuyến mãi (nếu có)'
                          onChange={(e) => setCouponCode(e.target.value)}
                        ></Form.Control>
                        <div className='col-6 mt-2 text-center'>
                          <Button
                            type='button'
                            className='btn-block btn-red'
                            disabled={cart.cartItemsData.length === 0}
                            onClick={() => applyCouponHandler(couponCode)} 
                          >
                            Áp dụng mã
                          </Button>
                        </div>
                        <div className='col-6 mt-2 text-center'>
                          <Button
                            type='button'
                            className='btn-block btn-red'
                            disabled={cart.cartItemsData.length === 0}
                            onClick={() => unapplyCouponHandler()} 
                          >
                            Xoá mã
                          </Button>
                        </div>
                      </Row>
                      {couponDetailData && couponDetailData.data && (
                        <Row>
                          <Col>Số tiền giảm ({couponDetailData.data.description})</Col>
                          <Col>- {parseInt(couponDiscountAmount).toLocaleString("vi", {
                                                          style: "currency",
                                                          currency: "VND",
                                                          })}</Col>
                        </Row>
                      )}
                  </ListGroup.Item>
                )}
                {/* Radio nhập sử dụng điểm */}
                {!showCouponTextBox && (
                  <ListGroup.Item>
                      <Row>
                        <Form.Control
                          as="select"
                          value={redeemMoney}
                          onChange={handleRedeemPointSelectChange}
                        >
                            <option value="0">---Chọn điểm cần sử dụng---</option>
                            {Object.keys(redeemablePointData).map((key) => (
                              <option key={key} value={redeemablePointData[key]}>
                                Đổi {key} điểm lấy {redeemablePointData[key]} đồng
                              </option>
                            ))}
                          </Form.Control>
                        <div className='col-6 mt-2 text-center'>
                          <Button
                            type='button'
                            className='btn-block btn-red'
                            disabled={cart.cartItemsData.length === 0}
                            onClick={() => applyRedeemPointHandler()} 
                          >
                            Áp dụng
                          </Button>
                        </div>
                        <div className='col-6 mt-2 text-center'>
                          <Button
                            type='button'
                            className='btn-block btn-red'
                            disabled={cart.cartItemsData.length === 0}
                            onClick={() => unapplyCouponHandler()} 
                          >
                            Huỷ bỏ
                          </Button>
                        </div>
                      </Row>
                      {isApplyRedeem && redeemMoney !== 0 &&(
                        <Row>
                          <Col>Số tiền giảm</Col>
                          <Col>- {parseInt(redeemMoney).toLocaleString("vi", {
                                                          style: "currency",
                                                          currency: "VND",
                                                          })}</Col>
                        </Row>
                      )}
                  </ListGroup.Item>
                )}
              </div>
              <ListGroup.Item>
                <Row>
                  <Col>Tổng tiền</Col>
                  <Col>{parseInt(cart.totalPrice).toLocaleString("vi", {
                                                    style: "currency",
                                                    currency: "VND",
                                                    })}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type='button'
                  className='btn-block btn-red'
                  disabled={cart.cartItemsData.length === 0 || couponDiscountAmount === 0 || errorOrderCreate || errorCoupon || redeemError || !isApplyRedeem || isSubmitOrder} 
                  onClick={placeOrderHandler}
                >
                  Đặt hàng
                </Button>
              </ListGroup.Item>
              {messageSettingData && couponDiscountAmount === 0 && <Message variant="danger">{isVietnamese ? JSON.parse(messageSettingData[0].vietnamese)["woocommerce_rest_invalid_coupon"]: JSON.parse(messageSettingData[0].english)["woocommerce_rest_invalid_coupon"]}</Message>}
              {messageSettingData && errorOrderCreate && <Message variant="danger">{isVietnamese ? JSON.parse(messageSettingData[0].vietnamese)[errorOrderCreate]: JSON.parse(messageSettingData[0].english)[errorOrderCreate]}</Message>}
              {messageSettingData && errorCoupon && <Message variant="danger">{isVietnamese ? JSON.parse(messageSettingData[0].vietnamese)[errorCoupon]: JSON.parse(messageSettingData[0].english)[errorCoupon]}</Message>}
              {messageSettingData && redeemError && <Message variant="danger">{isVietnamese ? JSON.parse(messageSettingData[0].vietnamese)['the_remaining_points_are_not_enough']: JSON.parse(messageSettingData[0].english)['the_remaining_points_are_not_enough']}</Message>}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default PlaceOrderScreen
