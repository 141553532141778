import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Product from "../components/Product";
import { listProductsFromAction, listProductsByCatIDFromAction, listCategoriesFromAction } from "../actions/productActions";
import Message from "../components/Message";
import Loader from "../components/Loader";
import "../liststyle.css";

const ProductListScreen = () => {
  //Tìm sản phẩm theo tên bằng javascript
  const [tableData, setTableData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch()

  //Lấy danh sách tất cả sản phẩm
  const productListFromScreen = useSelector((state) => state.productListFromStore)
  const { productListData, loading, error, success } = productListFromScreen

  //Lấy danh sách sản phẩm theo danh mục
  const productByCatIDListFromScreen = useSelector((state) => state.productByCatIDListFromStore)
  const { productsCategoryData, success:successProductByCategory } = productByCatIDListFromScreen

  //Lấy danh sách danh mục
  const categoryListFromScreen = useSelector((state) => state.categoryListFromStore)
  const { categoryListData, success: successCategory } = categoryListFromScreen

  //dispatch request to Action
  useEffect(() => {
    if (!success) {
      //Nếu chưa gọi hàm load danh sách sản phẩm thành công thì gọi hàm load danh sách sản phẩm
      dispatch(listProductsFromAction())
      //Gọi hàm lấy danh sách danh mục
      dispatch(listCategoriesFromAction())
    } else {
      //Nếu đã gọi hàm load danh sách sản phẩm thành công thì gán setTableData = productListData
      setTableData(productListData);
    }
    if(successProductByCategory){//Nếu có lọc sản phẩm từ combobox danh mục
      setTableData(productsCategoryData);
    }
  }, [dispatch, success, successProductByCategory]);

  //Thực hiện tìm sản phẩm từ danh sách bằng javascript
  const handleSearch = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchTerm(searchText);

    // Filter the tableData based on the search term
    const filteredData = tableData.filter(
      (item) => item.slug.toString().includes(searchText)
      //|| item.id.toLowerCase().includes(searchText)
    );
    //Nếu KH xoá hết mã đơn hàng vừa nhập thì tableData = orders
    setTableData(searchText !== "" ? filteredData : productListData);
  };

  return (
    <>
      {loading ? (
        <div className="loading-full">
          <Loader />
        </div>
      ) : (
        <>
          <div className="container content-mt bg-white">
            <Form.Group controlId="product_name" className="pt-3 pl-3 pr-3">
              <Form.Control
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Nhập tên sản phẩm cần tìm..."
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="category_name" className="mt-2 pl-3 pr-3">
                <Form.Control
                  as="select"
                  onChange={(e) =>
                    dispatch(
                      listProductsByCatIDFromAction(Number(e.target.value))
                    )
                  }
                >
                    <option value="1">---Chọn danh mục sản phẩm---</option>
                    {categoryListData.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.description}
                      </option>
                    ))}
                </Form.Control>
            </Form.Group>
            <div className="row m-0">
              {tableData.length === 0 ? (
                    <div className="col-md-12 mt-3">
                      <Message variant="danger">Không có sản phẩm</Message>
                    </div>
                  ) : (
                    <>
                      {tableData && tableData.map(
                        (
                          product //duyet tung product
                        ) => (
                          <div key={product.id} className='col-md-3 mt-3'>
                              <Product key={product.id} type={"list"} product={product} />
                          </div>
                        )
                      )}
                    </>
                  )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductListScreen;
