export const PROMOTION_POST_LIST_REQUEST = 'PROMOTION_POST_LIST_REQUEST'
export const PROMOTION_POST_LIST_SUCCESS = 'PROMOTION_POST_LIST_SUCCESS'
export const PROMOTION_POST_LIST_FAIL = 'PROMOTION_POST_LIST_FAIL'

export const TIP_POST_LIST_REQUEST = 'TIP_POST_LIST_REQUEST'
export const TIP_POST_LIST_SUCCESS = 'TIP_POST_LIST_SUCCESS'
export const TIP_POST_LIST_FAIL = 'TIP_POST_LIST_FAIL'

export const POST_DETAIL_REQUEST = 'POST_DETAIL_REQUEST'
export const POST_DETAIL_SUCCESS = 'POST_DETAIL_SUCCESS'
export const POST_DETAIL_FAIL = 'POST_DETAIL_FAIL'


