import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { login } from "../actions/userActions";
//Import LanguageContext được export từ App.js
import { LanguageContext } from '../App';

  const LoginScreen = ({ location, history }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  /*CHÚ Ý QUAN TRỌNG
  - Sau khi login thành công thì lưu thông tin user vào đối tượng userInfoData (ở hàm userReducer.js -> userLoginReducer)
  - Đối tượng này được định nghĩa trong store.js
  - Đối tượng này sẽ mất đi khi chuyển/refesh trang 
  -> Để sử dụng đối tượng userInfoData trong các màn hình mà không cần dispatch lại API userLogin 
  -> Thì trong store.js sử dụng Local storage để lưu đối tượng userInfoFromStorage
      const userInfoFromStorage = localStorage.getItem('userInfoFromStorage')
      ? JSON.parse(localStorage.getItem('userInfoFromStorage'))
      : null
  - Sau đó hàm initialState sẽ gán đối tượng Local Storage userInfoFromStorage vào các đối tượng trong userInfoData
    const initialState = {
      cartFromStore: {
        cartItemsData: cartItemsFromStorage,
      },
      userInfoFromStore: { userInfoData: userInfoFromStorage },
      messageSettingsFromStore: messageSettingsFromStorage,
    }
  - Đối tượng này sẽ được sử dụng để kiểm tra user đã đăng nhập chưa và được sử dụng trong các components dùng chung như Header.js
  - Khác với đối tượng userInfoData (ở hàm userReducer.js -> userRegisterReducer) được dùng trong RegisterScreen
*/
  const userLoginFromScreen = useSelector((state) => state.userInfoFromStore);
  const { loading, error, userInfoData } = userLoginFromScreen;

  //Lấy ngôn ngữ được chọn có phải tiếng Việt không từ biến dùng chung isVietnamese trong LanguageContext được khai báo trong App.js
  const { isVietnamese, setIsVietnamese } = useContext(LanguageContext);

  //messageSettingData chứa thông tin message như Trang chủ, Sản phẩm... cho các menu và label
  const messageSettingsFromScreen = useSelector((state) => state.messageSettingsFromStore);
  const { messageSettingData } = messageSettingsFromScreen;


  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfoData) {
      history.push(redirect);
    }
  }, [history, userInfoData, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
  };

  return (
    <div className="bg-red">
      <Container
        style={{
          minHeight: 700,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && (
          <div className="loading-full">
            <Loader />
          </div>
        )}
        <div className="box-login">
          <h1 className="titleSlide h3 mb-4">{messageSettingData && (isVietnamese ? JSON.parse(messageSettingData[0].vietnamese).login : JSON.parse(messageSettingData[0].english).login)}</h1>
          {error && messageSettingData && <Message variant="danger">{isVietnamese ? JSON.parse(messageSettingData[0].vietnamese).login_fail : JSON.parse(messageSettingData[0].english).login_fail}</Message>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="username">
              <Form.Label className="titleProduct mx-1">
                Số điện thoại:
              </Form.Label>
              <Form.Control
                type="phone"
                placeholder="Nhập số điện thoại"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label className="titleProduct mx-1">Password:</Form.Label>
              <Form.Control
                type="password"
                placeholder="Nhập mật khẩu"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
              <Link to={"/getotpresetpass"} className="link-forgot">
                Quên mật khẩu?
              </Link>
            </Form.Group>
            <Button type="submit" className="btn btn-red w-100">
              Đăng nhập
            </Button>
          </Form>
          <hr className="my-3"></hr>
          <Row>
            <Col>
              <p className="text-center mb-3">Bạn chưa có tài khoản?</p>
              <Link className="btn btn-red" to={"/register"}>
                Tạo mới tài khoản
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default LoginScreen;
