import React, { useState, useEffect, useContext } from "react";
import {
  ListGroup,
  Form,
  Button,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import OrderPopup from "../components/OrderPopup";
import { updateOrderStatus } from "../actions/orderActions";
import { listMyOrders, getOrderDetails } from "../actions/orderActions";
import Message from "../components/Message";
//Import LanguageContext được export từ App.js
import { LanguageContext } from "../App";

const OrderListScreen = ({ history, location }) => {
  const tableScroll = {
    maxHeight: "400px", // Điều chỉnh chiều cao của tbody để hiển thị thanh cuộn dọc khi nội dung vượt quá
    overflowY: "auto", // Tạo thanh cuộn dọc khi nội dung vượt quá chiều cao
    width: "100%",
  };
  const theadStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "#ea0429",
  };
  //Biến kiểm tra KH có thao tác cập nhật trạng thái đơn hàng không để hiển thị message
  const [isUpdateOrder, setIsUpdateOrder] = useState(false);

  //Lấy thông báo cập nhật đơn hàng thành công
  const [message, setMessage] = useState("");

  //Lấy ngôn ngữ được chọn có phải tiếng Việt không từ biến dùng chung isVietnamese trong LanguageContext được khai báo trong App.js
  const { isVietnamese, setIsVietnamese } = useContext(LanguageContext);
  //messageSettingData chứa thông tin message như Trang chủ, Sản phẩm... cho các menu và label
  const messageSettingsFromScreen = useSelector(
    (state) => state.messageSettingsFromStore
  );
  const { messageSettingData } = messageSettingsFromScreen;

  //Lấy thông tin shipping_fee, danh sách điểm có thể sử dụng redeemable_point từ appSettingsFromStore
  const appSettingsFromScreen = useSelector((state) => state.appSettingsFromStore)
  const { shipping_fee, redeemable_point } = appSettingsFromScreen

  const [orderId, setOrderId] = useState("");
  
  //Khi KH đã đăng nhập, sử dụng các biến dùng để thực hiện chức năng search theo mã đơn hàng bằng javascript
  const [tableData, setTableData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  //Popup chi tiết đơn hàng
  const [showPopup, setShowPopup] = useState(false);
  //Chứa ID của đơn hàng được chọn xem chi tiết
  const [selectedOrderId, setselectedOrderId] = useState("");
  //Sự kiện mở popup
  const handleOpenPopup = (orderId) => {
    setShowPopup(true);
    setselectedOrderId(orderId);
  };
  //Sự kiện đóng popup
  const handleClosePopup = () => {
    setShowPopup(false);
    //Reset lại selectedOrderId
    setselectedOrderId("");
  };
  //Sự kiện nhấn bên ngoài popup
  const handleOutsideClick = () => {
    handleClosePopup();
    //Reset lại selectedOrderId
    setselectedOrderId("");
  };

  const dispatch = useDispatch();

  /*CHÚ Ý QUAN TRỌNG
  - Sau khi login thành công thì lưu thông tin user vào đối tượng userInfoData (ở hàm userReducer.js -> userLoginReducer)
  - Đối tượng này được định nghĩa trong store.js
  - Đối tượng này sẽ mất đi khi chuyển/refesh trang 
  -> Để sử dụng đối tượng userInfoData trong các màn hình mà không cần dispatch lại API userLogin 
  -> Thì trong store.js sử dụng Local storage để lưu đối tượng userInfoFromStorage
      const userInfoFromStorage = localStorage.getItem('userInfoFromStorage')
      ? JSON.parse(localStorage.getItem('userInfoFromStorage'))
      : null
  - Sau đó hàm initialState sẽ gán đối tượng Local Storage userInfoFromStorage vào các đối tượng trong userInfoData
    const initialState = {
      cartFromStore: {
        cartItemsData: cartItemsFromStorage,
      },
      userInfoFromStore: { userInfoData: userInfoFromStorage },
      messageSettingsFromStore: messageSettingsFromStorage,
    }
  - Đối tượng này sẽ được sử dụng để kiểm tra user đã đăng nhập chưa và được sử dụng trong các components dùng chung như Header.js
  - Khác với đối tượng userInfoData (ở hàm userReducer.js -> userRegisterReducer) được dùng trong RegisterScreen
*/

  const userLoginFromScreen = useSelector((state) => state.userInfoFromStore);
  const { userInfoData } = userLoginFromScreen;

  //Danh sách đơn hàng
  const orderListMyFromScreen = useSelector(
    (state) => state.orderListMyFromStore
  );
  const {
    loading: loadingOrders,
    error: errorOrders,
    success: successOrders,
    orders,
  } = orderListMyFromScreen;

  //Chi tiết đơn hàng
  const orderDetailsFromScreen = useSelector(
    (state) => state.orderDetailsFromStore
  );
  const {
    loading: loadingOrderDetail,
    error: errorOrderDetail,
    order,
  } = orderDetailsFromScreen;

  useEffect(() => {
    if (userInfoData && !successOrders) {
      //Nếu đã đăng nhập và chưa gọi hàm load đơn hàng thành công thì gọi hàm load đơn hàng
      dispatch(listMyOrders(userInfoData.data.userid));
    } else {
      //Nếu đã gọi hàm load đơn hàng thành công thì gán tableData = orders
      setTableData(orders);
    }
    //Gán biến message bằng tham số message trên URL (nếu có)
    setMessage(new URLSearchParams(location.search).get("message"));
  }, [dispatch, userInfoData, orders, isUpdateOrder]);

  //Nếu KH đã đăng nhập thì thực hiện tìm mã đơn hàng từ danh sách đơn hàng bằng javascript
  const handleSearch = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchTerm(searchText);

    // Filter the tableData based on the search term
    const filteredData = tableData.filter(
      (item) => item.id.toString().includes(searchText)
      //|| item.id.toLowerCase().includes(searchText)
    );
    //Nếu KH xoá hết mã đơn hàng vừa nhập thì tableData = orders
    setTableData(searchText !== "" ? filteredData : orders);
  };

  //KH nhấn nút đã nhận được hàng
  const handleReceiveClick = (orderId) => {
    dispatch(updateOrderStatus(orderId, "received"));
    //Khi cập nhật đơn hàng thành công thì truyền message=update_order_status_success lên tham số
    history.push("/orderlist?message=update_order_status_success");
    //Và cập nhật lại biến isUpdateOrder để hiển thị message thành công (nếu có)
    setIsUpdateOrder(true);
  };

  //Nếu KH chưa đăng nhập khi tra cứu đơn hàng thì gọi API tra cứu đơn hàng
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(getOrderDetails(orderId));
  };

  return (
    <>
      {loadingOrders ? (
        <div className="loading-full">
          <Loader />
        </div>
      ) : (
        <>
          <div className="container pt-5 pb-5 bg-white">
            <div className="d-block justify-content-center">
              <h4>Tra cứu đơn hàng</h4>
              {messageSettingData && message && (
                <Message variant="success">
                  {isVietnamese
                    ? JSON.parse(messageSettingData[0].vietnamese)[message]
                    : JSON.parse(messageSettingData[0].english)[message]}
                </Message>
              )}
              {/* Nếu KH đã đăng nhập thì thực hiện tìm mã đơn hàng từ danh sách đơn hàng bằng javascript*/}
              {userInfoData && (
                <Form.Group controlId="display_name" className="mt-2">
                  <Form.Control
                    type="text"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Nhập mã đơn hàng"
                  ></Form.Control>
                </Form.Group>
              )}
              {/* Nếu KH chưa đăng nhập khi tra cứu đơn hàng thì gọi API tra cứu đơn hàng*/}
              {!userInfoData && (
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId="display_name" className="mt-2">
                    <Form.Control
                      type="orderId"
                      value={orderId}
                      onChange={(e) => setOrderId(e.target.value)}
                      placeholder="Nhập mã đơn hàng"
                      required
                    ></Form.Control>
                  </Form.Group>
                  <Button
                    type="submit"
                    variant="primary"
                    className="btn btn-red"
                  >
                    Tra cứu
                  </Button>
                </Form>
              )}
            </div>
            {/* Nếu KH đã đăng nhập */}
            {userInfoData && (
              <div className="d-block justify-content-center mt-3">
                {errorOrders ? (
                  <Message variant="danger">{errorOrders}</Message>
                ) : (
                  <>
                    <h4 className="my-2">Danh sách đơn hàng</h4>
                    <div style={tableScroll}>
                      {/* Nếu kích thước màn hình >= MD -> table hiển thị 7 cột */}
                      {window.innerWidth >= 768 && (
                        <table className="table table-striped table-bordered align-top mt-3">
                          <thead style={theadStyle}>
                            <tr>
                              <th
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  fontSize: "13px",
                                  width: "10%",
                                  padding: "0 2px",
                                }}
                              >
                                Mã đơn hàng
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  fontSize: "13px",
                                  width: "13%",
                                  padding: "0 2px",
                                }}
                              >
                                Ngày tạo
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  fontSize: "13px",
                                  width: "15%",
                                  padding: "0 2px",
                                }}
                              >
                                Tổng tiền
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  fontSize: "13px",
                                  width: "15%",
                                  padding: "0 2px",
                                }}
                              >
                                Trạng thái
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  fontSize: "13px",
                                  width: "15%",
                                  padding: "0 2px",
                                }}
                              >
                                Thanh toán
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  fontSize: "13px",
                                  width: "15%",
                                  padding: "0 2px",
                                }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData &&
                              tableData.map((order) => (
                                <tr key={order.id}>
                                  <td>{order.id}</td>
                                  <td>{order.date_created.substring(0, 10)}</td>
                                  <td>{parseInt(order.total).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</td>
                                  <td>
                                    {order.status === "completed" ? (
                                      <>
                                        Giao thành công lúc{" "}
                                        {order.date_completed}
                                      </>
                                    ) : order.status === "on-hold" ? (
                                      "Chờ tiếp nhận"
                                    ) : order.status === "processing" ? (
                                      "Đang giao hàng"
                                    ) : order.status === "received" ? (
                                      "Đã nhận được hàng"
                                    ) : (
                                      "Vui lòng liên hệ shop"
                                    )}
                                  </td>
                                  <td>
                                    {order.payment_method === "BankTransfer"
                                      ? "Chuyển khoản"
                                      : order.payment_method === "COD"
                                      ? "Thanh toán khi nhận hàng"
                                      : "Vui lòng chọn hình thức thanh toán"}
                                    {order.date_completed != null ? (
                                      <>
                                        ( Đã thanh toán lúc {order.date_paid})
                                      </>
                                    ) : (
                                      " (Chưa thanh toán)"
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {order.status === "received" && (
                                      <a
                                        onClick={() =>
                                          handleReceiveClick(order.id)
                                        }
                                        className="mytooltip"
                                      >
                                        <p className="tooltip-text">
                                          Đã nhận được hàng
                                        </p>
                                        <span className="material-icons green">
                                          fact_check
                                        </span>
                                      </a>
                                    )}
                                    {order.status !== "received" && (
                                      <a
                                        onClick={() =>
                                          handleReceiveClick(order.id)
                                        }
                                        className="mytooltip"
                                      >
                                        <p className="tooltip-text">
                                          Cập nhật đã nhận được hàng
                                        </p>
                                        <span className="material-icons red">
                                          article
                                        </span>
                                      </a>
                                    )}
                                    <a
                                      href={`/order/${order.id}`}
                                      className="mytooltip"
                                    >
                                      <p className="tooltip-text">
                                        Xem chi tiết
                                      </p>
                                      <span className="material-icons red">
                                        visibility
                                      </span>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}

                      {/* Nếu kích thước màn hình < MD -> table hiển thị 3 cột */}
                      {window.innerWidth < 768 && (
                        <table className="table table-striped table-bordered align-top mt-3">
                          <thead style={theadStyle}>
                            <tr>
                              <th
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  fontSize: "13px",
                                  width: "10%",
                                  padding: "0 2px",
                                }}
                              >
                                Mã đơn hàng
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  fontSize: "13px",
                                  width: "13%",
                                  padding: "0 2px",
                                }}
                              >
                                Đã nhận hàng
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  fontSize: "13px",
                                  width: "15%",
                                  padding: "0 2px",
                                }}
                              >
                                Chi tiết
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData &&
                              tableData.map((order) => (
                                <>
                                  <tr key={order.id}>
                                    <td>{order.id}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {order.status === "received" && (
                                        <a href="#" className="mytooltip">
                                          <p className="tooltip-text">
                                            Đã nhận được hàng
                                          </p>
                                          <span className="material-icons green">
                                            fact_check
                                          </span>
                                        </a>
                                      )}
                                      {order.status !== "received" && (
                                        <a
                                          onClick={() =>
                                            handleReceiveClick(order.id)
                                          }
                                          className="mytooltip"
                                        >
                                          <p className="tooltip-text">
                                            Cập nhật đã nhận được hàng
                                          </p>
                                          <span className="material-icons red">
                                            article
                                          </span>
                                        </a>
                                      )}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <a
                                        onClick={() =>
                                          handleOpenPopup(order.id)
                                        }
                                        className="mytooltip"
                                      >
                                        <p className="tooltip-text">
                                          Xem chi tiết
                                        </p>
                                        <span className="material-icons red">
                                          visibility
                                        </span>
                                      </a>
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </>
                )}
                {/* Vị trí đặt popup */}
                {showPopup && (
                  <OrderPopup
                    content={selectedOrderId}
                    onClose={handleClosePopup}
                    onOutsideClick={handleOutsideClick}
                  />
                )}
              </div>
            )}
            {/* Nếu KH chưa đăng nhập */}
            {!userInfoData && (
              <div className="d-block justify-content-center mt-2">
                {errorOrderDetail && (
                  // <Message variant='danger'>{errorOrderDetail}</Message>
                  <Message variant="danger">
                    Vui lòng đăng nhập để xem đơn hàng này
                  </Message>
                )}
                {order && (
                  <>
                    <h4 className="my-2">Chi tiết đơn hàng {order.id}</h4>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        {order.customer_id !== 0 && (
                          <>
                            <p>Họ và tên: {order.billing.first_name}</p>
                            <p>Địa chỉ nhận hàng: {order.billing.address_1}</p>
                            <p>Số điện thoại: {order.billing.phone}</p>
                          </>
                        )}
                        <p>
                          <strong>Trạng thái đơn hàng: </strong>
                          {order.status === "completed" ? (
                            <>Giao thành công lúc {order.date_completed}</>
                          ) : order.status === "on-hold" ? (
                            "Chờ tiếp nhận"
                          ) : order.status === "processing" ? (
                            "Đang giao hàng"
                          ) : (
                            "Vui lòng liên hệ shop"
                          )}
                        </p>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <strong>Trạng thái thanh toán: </strong>{" "}
                        {order.payment_method === "BankTransfer"
                          ? "Chuyển khoản"
                          : order.payment_method === "COD"
                          ? "Thanh toán khi nhận hàng"
                          : "Vui lòng chọn hình thức thanh toán"}
                        {order.date_completed != null ? (
                          <>(Đã thanh toán lúc {order.date_paid})</>
                        ) : (
                          " (Chưa thanh toán)"
                        )}
                      </ListGroup.Item>
                      {/* Nếu có coupon và coupon là fixed_cart) */}
                      {order.coupon_lines && order.coupon_lines[0] && order.coupon_lines[0].meta_data[0] && !order.coupon_lines[0].meta_data[0].value.free_shipping && (
                        <>
                        <ListGroup.Item>
                          <Row>
                            <Col><strong>Tiền sản phẩm</strong></Col>
                            <Col>{(parseInt(order.total) + parseInt(order.coupon_lines[0].discount) - parseInt(order.shipping_total)).toLocaleString("vi", {
                                                          style: "currency",
                                                          currency: "VND",
                                                          })}</Col>

                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col><strong>Phí vận chuyển</strong></Col>
                            <Col>{parseInt(order.shipping_total).toLocaleString("vi", {
                                                          style: "currency",
                                                          currency: "VND",
                                                          })}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col><strong>Khuyến mãi </strong><span>{'\n'}</span> ({order.coupon_lines[0].meta_data[0].value.description})</Col>
                            <Col>- {parseInt(order.coupon_lines[0].discount).toLocaleString("vi", {
                                                          style: "currency",
                                                          currency: "VND",
                                                          })}</Col>
                          </Row>
                        </ListGroup.Item>
                        </>
                      )}
                      {/* Nếu có coupon và coupon là freeship */}
                      {order.coupon_lines && order.coupon_lines[0] && order.coupon_lines[0].meta_data[0] && order.coupon_lines[0].meta_data[0].value.free_shipping && (
                        <>
                        <ListGroup.Item>
                          <Row>
                            <Col><strong>Tiền sản phẩm</strong></Col>
                            <Col>{parseInt(order.total).toLocaleString("vi", {
                                                          style: "currency",
                                                          currency: "VND",
                                                          })}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col><strong>Phí vận chuyển</strong></Col>
                            <Col>{parseInt(shipping_fee).toLocaleString("vi", {
                                                          style: "currency",
                                                          currency: "VND",
                                                          })}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col><strong>Khuyến mãi</strong><span>{'\n'}</span> ({order.coupon_lines[0].meta_data[0].value.description})</Col>
                            <Col>- {parseInt(shipping_fee).toLocaleString("vi", {
                                                          style: "currency",
                                                          currency: "VND",
                                                          })}</Col>
                          </Row>
                        </ListGroup.Item>
                        </>
                      )}
                      {/* Nếu không có coupon */}
                      {order.coupon_lines.length === 0 && order.fee_lines.length === 0 &&  (
                        <>
                        <ListGroup.Item>
                          <Row>
                            <Col>Tiền sản phẩm</Col>
                            <Col>{(parseInt(order.total) - parseInt(order.shipping_total)).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>Phí vận chuyển</Col>
                            <Col>{parseInt(order.shipping_total).toLocaleString("vi", {
                                                          style: "currency",
                                                          currency: "VND",
                                                          })}</Col>
                          </Row>
                        </ListGroup.Item>
                        </>
                      )}
                      <ListGroup.Item>
                        <Row>
                          <Col><strong>Tổng tiền</strong></Col>
                          <Col>{parseInt(order.total).toLocaleString("vi", {
                                                          style: "currency",
                                                          currency: "VND",
                                                          })}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Danh sách sản phẩm</strong>
                        {order.line_items.length === 0 ? (
                          <Message>Đơn hàng chưa có sản phẩm</Message>
                        ) : (
                          <ListGroup variant="flush">
                            {order.line_items.map((item, index) => (
                              <ListGroup.Item key={index}>
                                <Row>
                                  <Col md={1}>
                                    <Image
                                      src={item.image.src}
                                      alt={item.name}
                                      fluid
                                      rounded
                                    />
                                  </Col>
                                  <Col>
                                    <Link to={`/product/${item.slug}`}>
                                      {item.name}
                                    </Link>
                                  </Col>
                                  <Col md={4}>
                                    {/* Nếu có coupon và coupon là freeship */}
                                    {order.coupon_lines && order.coupon_lines[0] && order.coupon_lines[0].meta_data[0] && order.coupon_lines[0].meta_data[0].value.free_shipping && (
                                      <>
                                      {item.quantity} x {parseInt(item.price).toLocaleString("vi", {
                                      style: "currency",
                                      currency: "VND",
                                      })} = {parseInt(item.quantity * item.price).toLocaleString("vi", {
                                        style: "currency",
                                        currency: "VND",
                                      })}
                                      </>
                                    )}
                                    {/* Nếu có coupon và coupon là fixed_cart) */}
                                    {order.coupon_lines && order.coupon_lines[0] && order.coupon_lines[0].meta_data[0] && !order.coupon_lines[0].meta_data[0].value.free_shipping && (
                                      <>
                                      {item.quantity} x {(parseInt(item.subtotal)/parseInt(item.quantity)).toLocaleString("vi", {
                                                            style: "currency",
                                                            currency: "VND",
                                                            })} = {parseInt(item.subtotal).toLocaleString("vi", {
                                                            style: "currency",
                                                            currency: "VND",
                                                            })}
                                      </>
                                    )}
                                    {/* Nếu không có coupon */}
                                    {order.coupon_lines.length === 0 && (
                                      <>
                                      {item.quantity} x {parseInt(item.price).toLocaleString("vi", {
                                                            style: "currency",
                                                            currency: "VND",
                                                            })} = {parseInt(item.quantity * item.price).toLocaleString("vi", {
                                                              style: "currency",
                                                              currency: "VND",
                                                              })}
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        )}
                      </ListGroup.Item>
                    </ListGroup>
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OrderListScreen;
