import {
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_BESTSELLER_LIST_REQUEST,
  PRODUCT_BESTSELLER_LIST_SUCCESS,
  PRODUCT_BESTSELLER_LIST_FAIL,
  PRODUCT_SALE_LIST_REQUEST,
  PRODUCT_SALE_LIST_SUCCESS,
  PRODUCT_SALE_LIST_FAIL,
  PRODUCT_BY_CATID_LIST_REQUEST,
  PRODUCT_BY_CATID_LIST_SUCCESS,
  PRODUCT_BY_CATID_LIST_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_RESET,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  APP_SETTINGS_LIST_SUCCESS,
  MESSAGE_SETTINGS_LIST_SUCCESS,
} from '../constants/productConstants'

export const productListFromReducer = (state = { productListData: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, productListData: [] }
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        success:true,
        productListData: action.payload,
        // pages: action.payload.pages,
        // page: action.payload.page,
      }
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productBestSellerListFromReducer = (state = { productsBestSellerData: [] }, action) => {
  switch (action.type) {
    case PRODUCT_BESTSELLER_LIST_REQUEST:
      return { loading: true, productsBestSellerData: [] }
    case PRODUCT_BESTSELLER_LIST_SUCCESS:
      return {
        loading: false,
        productsBestSellerData: action.payload,
      }
    case PRODUCT_BESTSELLER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productSaleListFromReducer = (state = { productsSaleData: [] }, action) => {
  switch (action.type) {
    case PRODUCT_SALE_LIST_REQUEST:
      return { loading: true, productsSaleData: [] }
    case PRODUCT_SALE_LIST_SUCCESS:
      return {
        loading: false,
        productsSaleData: action.payload,
      }
    case PRODUCT_SALE_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productByCatIDListFromReducer = (state = { productsCategoryData: [] }, action) => {
  switch (action.type) {
    case PRODUCT_BY_CATID_LIST_REQUEST:
      return { loading: true, productsCategoryData: [] }
    case PRODUCT_BY_CATID_LIST_SUCCESS:
      return {
        loading: false,
        success:true,
        productsCategoryData: action.payload,
      }
    case PRODUCT_BY_CATID_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productDetailsFromReducer = (
  state = { productDetailData: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, productDetailData: action.payload }
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productReviewCreateFromReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}

export const categoryListFromReducer = (state = { categoryListData: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true, categoryListData: [] }
    case CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        success:true,
        categoryListData: action.payload,
      }
    case CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const appSettingsFromReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case APP_SETTINGS_LIST_SUCCESS:
      return { shipping_fee: action.payload.shipping_fee, default_image_url: action.payload.default_image_url, redeemable_point: action.payload.redeemable_point }
    default:
      // Kiểm tra nếu tồn tại appSettingsFromStorage trong localStorage
      // thì gán appSettingsFromStore = appSettingsFromStorage
      const appSettingsFromStorage = localStorage.getItem('appSettingsFromStorage');
      return appSettingsFromStorage
        ? { shipping_fee: JSON.parse(appSettingsFromStorage).shipping_fee, default_image_url: JSON.parse(appSettingsFromStorage).default_image_url, redeemable_point: JSON.parse(appSettingsFromStorage).redeemable_point}
        : state;
  }
}

export const messageSettingsFromReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case MESSAGE_SETTINGS_LIST_SUCCESS:
      return { messageSettingData: action.payload}
    default:
      // Kiểm tra nếu tồn tại messageSettingsFromStorage trong localStorage
      // thì gán messageSettingsFromStore = messageSettingsFromStorage
      const messageSettingData = JSON.parse(localStorage.getItem('messageSettingsFromStorage'));
      return messageSettingData
        ? messageSettingData
        : state;
  }
}
