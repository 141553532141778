import React from 'react';

const EmbeddedPage = ({ url, title }) => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe 
        src={url}
        title={title}
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
};
export default EmbeddedPage;
