import React from "react";
import {
  FaFacebook,
  FaFacebookMessenger,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
const Footer = () => {
  return (
      <div className="footer">
        <div className="container no-padding" style={{ maxWidth: 1600}}>
          <div className="footer-background">
            <div className="row m-0" style={{ position: 'absolute', top: '9%', left: '50%', zIndex: '99'}}>
              <div className="col">
                <a href="https://www.facebook.com/kojiesan.vietnam.vn" target="_blank" rel="noopener noreferrer">
                  <FaFacebook className="iconFooter" />
                </a>
                {/* <FaYoutube className="iconFooter" /> */}
                <a href="https://www.facebook.com/messages/t/1645975378971028" target="_blank" rel="noopener noreferrer">
                  <FaFacebookMessenger className="iconFooter" />
                </a>
                {/* <FaInstagram className="iconFooter" /> */}
              </div>
            </div>
            <div className="row m-0" style={{ position: 'relative' }}>
              <div className="col pt-5">
                <h5>LIÊN HỆ</h5>
              </div>
            </div>
          </div>
          <div className="row pl-2 pb-2 m-0" style={{ backgroundColor: '#050505' }}>
            <div className="col-12 col-lg-4">
              <ul>
                <li className="font-weight-bold">
                  Công Ty cổ phần Thảo Kim Hoàn
                </li>
                <li>
                  <span className="font-weight-bold">Showroom:</span> Tầng Trệt
                  Toà nhà K.L 36 Thống Nhất <br/> P.10, Q.Gò Vấp, TP.Hồ Chí Minh, Việt
                  Nam
                </li>
                <li>
                  <span className="font-weight-bold">Hotline:</span>
                  0937245789
                </li>
                <li>
                  <span className="font-weight-bold">Email:</span>{" "}
                  info@kojiesan.vn
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-4">
              <ul>
                <li>
                  <a href="/orderguide" style={{color: 'white'}}><span className="font-weight-bold">Hướng dẫn mua hàng</span></a>
                </li>
                <li>
                  <a href="/loyaltypolicy" style={{color: 'white'}}><span className="font-weight-bold">Chương trình hội viên</span></a>
                </li>
                <li>
                  <a href="/purchasepolicy" style={{color: 'white'}}><span className="font-weight-bold">Chính sách mua hàng</span></a>
                </li>
                <li>
                  <a href="/privacy" style={{color: 'white'}}><span className="font-weight-bold">Chính sách bảo mật</span></a>
                </li>
              </ul>
            </div>

            {/* Nếu kích thước màn hình > MD -> hiển thị QR tải app */}
            {window.innerWidth >= 768 && (
              <>
                <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center pt-5 pt-lg-0">
                <img
                    width="20%"
                    src={"/images/QR.svg"}
                  />
                <div>
                  <div>
                    <a href="https://apps.apple.com/app/kojiesan/id6504525898" target="_blank">
                      <img
                        width="100%"
                        src={"/images/app_store.svg"}
                      />
                    </a>
                  </div>
                  <div className="mt-3">
                    <a href="https://play.google.com/store/apps/details?id=com.kojiesanvn" target="_blank">
                      <img
                        width="100%"
                        src={"/images/google_play.svg"}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center align-items-center pt-5 pt-lg-0">
                <a href="http://online.gov.vn/CustomWebsiteDisplay.aspx?DocId=35314">
                  <img
                    width="200"
                    height="50"
                    src={"/images/dathongbao-300x114.png"}
                  />
                </a>
              </div>
              </>
            )}

            {/* Nếu kích thước màn hình < MD -> không hiển thị QR tải app */}
            {window.innerWidth < 768 && (
              <>
              <div className="col-12 d-flex">
                <div className="justify-content-center align-items-center pt-3 pt-lg-0">
                  <div>
                    <div>
                      <a href="https://apps.apple.com/app/kojiesan/id6504525898" target="_blank">
                        <img
                          width="80%"
                          src={"/images/app_store.svg"}
                        />
                      </a>
                    </div>
                    <div className="mt-3">
                      <a href="https://play.google.com/store/apps/details?id=com.kojiesanvn" target="_blank">
                        <img
                          width="80%"
                          src={"/images/google_play.svg"}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="justify-content-center align-items-center pt-3 pt-lg-0">
                  <a href="http://online.gov.vn/CustomWebsiteDisplay.aspx?DocId=35314">
                    <img
                      width="200"
                      height="50"
                      src={"/images/dathongbao-300x114.png"}
                    />
                  </a>
                </div>
              </div>
              </>               
            )}
          </div>
        </div>
      </div>
  );
};

export default Footer;
