import React, { useState, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { resetPasswordWithOTP } from "../actions/userActions";

const ResetPasswordWithOTPScreen = ({ location, history }) => {
  const [otp, setOtp] = useState("");
  const [new_pass, setNew_pass] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const userResetPasswordWithOTPFromScreen = useSelector(
    (state) => state.userResetPasswordWithOTPFromStore
  );
  const { loading, error, userResetPasswordWithOTPData } =
    userResetPasswordWithOTPFromScreen;

  //const redirect = location.search ? location.search.split('=')[1] : '/'

  const username = new URLSearchParams(location.search).get("username");

  const redirect = "/login";

  useEffect(() => {
    if (userResetPasswordWithOTPData) {
      history.push(redirect);
    }
  }, [history, userResetPasswordWithOTPData, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (new_pass === "") {
      setMessage("Vui long nhap mat khau");
    } else {
      dispatch(resetPasswordWithOTP(username, otp, new_pass));
    }
  };

  return (
    <div className="bg-red">
      <Container
        style={{
          minHeight: 700,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="box-login">
          <h1 className="titleSlide h3 mb-4">Tạo lại mật khẩu</h1>
          {message && <Message variant="danger">{message}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="otp">
              <Form.Label className="titleProduct mx-1">OTP</Form.Label>
              <Form.Control
                type="otp"
                placeholder="Nhập OPT"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="pass">
              <Form.Label className="titleProduct mx-1">Mật khẩu</Form.Label>
              <Form.Control
                type="password"
                placeholder="Nhập mật khẩu"
                value={new_pass}
                onChange={(e) => setNew_pass(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary" className="btn btn-red w-100">
              Tạo lại mật khẩu
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default ResetPasswordWithOTPScreen;
