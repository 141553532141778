import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productListFromReducer,
  productBestSellerListFromReducer,
  productSaleListFromReducer,
  productByCatIDListFromReducer,
  productDetailsFromReducer,
  productReviewCreateFromReducer,
  categoryListFromReducer,
  appSettingsFromReducer,
  messageSettingsFromReducer,
} from './reducers/productReducers'
import { cartReducer } from './reducers/cartReducers'
import {
  userLoginReducer,
  guestInfoReducer,
  userRegisterReducer,
  userInitPasswordWithOTPReducer,
  userResetPasswordWithOTPReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  customerPointsAndMembershipReducer,
  customerListPointReducer,
} from './reducers/userReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderListMyReducer,
  couponDetailsReducer,
} from './reducers/orderReducers'
import {
  postPromotionListFromReducer,
  postTipListFromReducer,
  postDetailsData,
} from './reducers/postReducers'

const reducer = combineReducers({
  productListFromStore: productListFromReducer,
  productBestSellerListFromStore: productBestSellerListFromReducer,
  productSaleListFromStore: productSaleListFromReducer,
  productByCatIDListFromStore: productByCatIDListFromReducer,
  productDetailsFromStore: productDetailsFromReducer,
  productReviewCreateFromStore: productReviewCreateFromReducer,
  categoryListFromStore: categoryListFromReducer,
  cartFromStore: cartReducer,
  userInfoFromStore: userLoginReducer,
  guestInfoFromStore: guestInfoReducer,
  userRegisterFromStore: userRegisterReducer,
  userInitPasswordWithOTPFromStore: userInitPasswordWithOTPReducer,
  userResetPasswordWithOTPFromStore: userResetPasswordWithOTPReducer,
  userDetailsFromStore: userDetailsReducer,
  customerListPointFromStore: customerListPointReducer,
  userUpdateProfileFromStore: userUpdateProfileReducer,
  customerPointsAndMembershipFromStore: customerPointsAndMembershipReducer,
  orderCreateFromStore: orderCreateReducer,
  orderDetailsFromStore: orderDetailsReducer,
  orderListMyFromStore: orderListMyReducer,
  couponDetailsFromStore: couponDetailsReducer,
  appSettingsFromStore: appSettingsFromReducer,
  messageSettingsFromStore: messageSettingsFromReducer,
  postPromotionListFromStore: postPromotionListFromReducer,
  postTipListFromStore: postTipListFromReducer,
  postDetailsFromStore:postDetailsData,
})

const cartItemsFromStorage = localStorage.getItem('cartItemsFromStorage')
  ? JSON.parse(localStorage.getItem('cartItemsFromStorage'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfoFromStorage')
  ? JSON.parse(localStorage.getItem('userInfoFromStorage'))
  : null

//Lưu thông tin display_name, address,...của KH khi đặt hàng mà chưa đăng ký
const guestInfoFromStorage = localStorage.getItem('guestInfoFromStorage')
? JSON.parse(localStorage.getItem('guestInfoFromStorage'))
: null

const shippingAddressFromStorage = localStorage.getItem('shippingAddressFromStorage')
  ? JSON.parse(localStorage.getItem('shippingAddressFromStorage'))
  : {}

const paymentMethodFromStorage = localStorage.getItem('paymentMethodFromStorage')
  ? JSON.parse(localStorage.getItem('paymentMethodFromStorage'))
  : {}

//Lưu thông tin ghi chú đơn hàng orderNote
const orderNoteFromStorage = localStorage.getItem('orderNoteFromStorage')
? JSON.parse(localStorage.getItem('orderNoteFromStorage'))
: null

//Lưu thông tin message
const messageSettingsFromStorage = localStorage.getItem('messageSettingsFromStorage')
  ? JSON.parse(localStorage.getItem('messageSettingsFromStorage'))
  : {}

//Hàm initialState sẽ gán các đối tượng Local Storage (..Storage) vào các đối tượng trong redux (..Store)
const initialState = {
  cartFromStore: {
    cartItemsData: cartItemsFromStorage,
    shippingAddressData: shippingAddressFromStorage,
    paymentMethodData: paymentMethodFromStorage,
    orderNoteData: orderNoteFromStorage,
  },
  userInfoFromStore: { 
    userInfoData: userInfoFromStorage,
  },
  guestInfoFromStore: {guestInfoData:guestInfoFromStorage},
  messageSettingsFromStore: messageSettingsFromStorage,
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
