import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getOrderDetails } from '../actions/orderActions'
import '../orderpopup.css';

const OrderPopup = ({ content, onClose, onOutsideClick }) => {
  //Chi tiết đơn hàng
  const orderDetailsFromScreen = useSelector((state) => state.orderDetailsFromStore)
  const { loading: loadingOrderDetail, error: errorOrderDetail, success: successOrderDetail, order} = orderDetailsFromScreen

  const dispatch = useDispatch()

  useEffect(() => {
    // if(!successOrderDetail){
      dispatch(getOrderDetails(content))
    // }
  }, [dispatch])

  return (
    <div className="popup-overlay" onClick={onOutsideClick}>
      <div className="popup-content">
        <div className="popup-header">
          {/* Hiển thị nội dung của header */}
          <h2>Chi tiết đơn hàng {content}</h2>
          <span className="close-icon" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className='px-1'>
          {order && (
            <>
            <p>Ngày tạo: {order.date_created.substring(0, 10)}</p>
            <p>Tổng tiền: {parseInt(order.total).toLocaleString("vi", {
                            style: "currency",
                            currency: "VND",
                          })}</p>
            <p>Trạng thái:
            {order.status === 'completed' ? (
                              <>
                                Giao thành công lúc {order.date_completed}
                              </>
                            ) : order.status === 'on-hold'?(
                                'Chờ tiếp nhận'
                            ) : order.status === 'processing'?(
                                'Đang giao hàng'
                            ) : order.status === 'received'?(
                                'Đã nhận được hàng'
                            ):
                            (
                                'Vui lòng liên hệ shop'
                            )}
            </p>
            <p>Hình thức thanh toán: 
                {order.payment_method === 'BankTransfer' ? 'Chuyển khoản': order.payment_method === 'COD' ? 'Thanh toán khi nhận hàng':'Vui lòng chọn hình thức thanh toán'}
            </p>
            <p>Trạng thái thanh toán: 
            {order.date_completed != null ? (
                              <>
                                ( Đã thanh toán lúc {order.date_paid})
                              </>
                            ) : (
                              ' (Chưa thanh toán)'
                            )}
            </p>
            <p>Ghi chú: {order.customer_note}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderPopup;
