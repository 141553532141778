import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import axios from 'axios'
import "../liststyle.css"
import {
  CMS_URL,
} from '../constants/webConstants'

const Post = ({ type, post }) => {

  //Lấy thông tin shipping_fee từ appSettingsFromStore
  const appSettingsFromScreen = useSelector((state) => state.appSettingsFromStore)
  const { default_image_url } = appSettingsFromScreen

  const [featuredmediaurl, setFeaturedmediaurl] = useState(default_image_url)

  useEffect(() => {
    // Gọi API lấy feature image của post có featured_media
    if(post.featured_media!==0){
      axios.get(
        `${CMS_URL}/wp-json/wp/v2/media/${post.featured_media}`,
        {
          headers: {
            accept: 'application/json',
          },
        }
      ).then((response) => {
        //Cập nhật URL của feature image vào featured_media_url
        setFeaturedmediaurl(response.data.source_url)
      })
    }
  }, [null, featuredmediaurl])

  return (
    <>
      {type === 'promotion' && (
        <a className="text-decoration-none" href={`/post/${post.id}`}>
          <div className="row p-2 bg-white border rounded">
            <div className="col-md-3 mt-1"><img className="img-fluid img-responsive rounded product-image" src={featuredmediaurl}/></div>
            <div className="col-md-6 mt-1">
                <h5>{post.title.rendered}</h5>
                {/* <div className="d-flex flex-row">
                    <div className="ratings mr-2"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></div>
                </div> */}
                <p className="text-justify text-truncate para mb-0"><div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered?post.excerpt.rendered:'' }} /></p>
            </div>
          </div>
        </a>
      )}
    </>
  )
}

export default Post
