import React from "react";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Product from "./Product";

const ProductSwiper = ({ products, name }) => {
  const navigationStyle = {
    color: "#ea0429", // Màu của nút Navigation trong file này
  };
  const productSlide = products.map((product, key) => (
    <SwiperSlide key={key}>
      <Product id={product.id} slug={product.slug} type={"home"} product={product} />
    </SwiperSlide>
  ));

  return (
    <div className="box px-2 px-md-3 px-lg-4">
      <h1 className="titleSlide h2 mb-3">{name}</h1>
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={20}
        navigation
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 5,
          },
        }}
      >
        {productSlide}
      </Swiper>
    </div>
  );
};

export default ProductSwiper;
