import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listBestSellerProductsFromAction,
  listSaleProductsFromAction,
  getAppSettings,
} from "../actions/productActions";
import Banner from "../components/Banner";
import ProductSwiper from "../components/ProductSwiper.js";
import Loader from "../components/Loader";

const HomeScreen = () => {
  const dispatch = useDispatch();
  //dispatch request to Action
  useEffect(() => {
    dispatch(listBestSellerProductsFromAction());
    dispatch(listSaleProductsFromAction());
    dispatch(getAppSettings());
  }, [dispatch]);

  //Lấy danh sách sản phẩm bán chạy theo categoryID=233
  const productBestSellerListFromScreen = useSelector(
    (state) => state.productBestSellerListFromStore
  );

  const { productsBestSellerData, loading } = productBestSellerListFromScreen;
  //Lấy danh sách sản phẩm khuyến mãi theo categoryID=232
  const productSaleListFromScreen = useSelector(
    (state) => state.productSaleListFromStore
  );
  const { productsSaleData } = productSaleListFromScreen;

  return (
    <div className="bg-white">
      {loading ? (
        <div className="loading-full">
          <Loader />
        </div>
      ) : (
        <>
          <div>
            <Banner banner_app_type={"web"} banner_type={"banner_home"} />
          </div>
          {/* Phần Intro Before/After */}
          <Banner banner_app_type={"web"} banner_type={"intro"}/>
          <ProductSwiper
            products={productsBestSellerData}
            name={"Sản Phẩm Bán Chạy"}
          />
          {productsSaleData.length !== 0 && 
          (
            <ProductSwiper
            products={productsSaleData}
            name={"Sản Phẩm Khuyến Mãi"}
          />
          )}
          {/* Phần review của KH */}
          <Banner banner_app_type={"web"} banner_type={"review"}/>
        </>
      )}
    </div>
  );
};

export default HomeScreen;
