export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_BESTSELLER_LIST_REQUEST = 'PRODUCT_BESTSELLER_LIST_REQUEST'
export const PRODUCT_BESTSELLER_LIST_SUCCESS = 'PRODUCT_BESTSELLER_LIST_SUCCESS'
export const PRODUCT_BESTSELLER_LIST_FAIL = 'PRODUCT_BESTSELLER_LIST_FAIL'

export const PRODUCT_SALE_LIST_REQUEST = 'PRODUCT_SALE_LIST_REQUEST'
export const PRODUCT_SALE_LIST_SUCCESS = 'PRODUCT_SALE_LIST_SUCCESS'
export const PRODUCT_SALE_LIST_FAIL = 'PRODUCT_SALE_LIST_FAIL'

export const PRODUCT_BY_CATID_LIST_REQUEST = 'PRODUCT_BY_CATID_LIST_REQUEST'
export const PRODUCT_BY_CATID_LIST_SUCCESS = 'PRODUCT_BY_CATID_LIST_SUCCESS'
export const PRODUCT_BY_CATID_LIST_FAIL = 'PRODUCT_BY_CATID_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL'

export const APP_SETTINGS_LIST_SUCCESS = 'APP_SETTINGS_LIST_SUCCESS'
export const MESSAGE_SETTINGS_LIST_SUCCESS = 'MESSAGE_SETTINGS_LIST_SUCCESS'
