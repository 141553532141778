import axios from 'axios'
import {
  PROMOTION_POST_LIST_REQUEST,
  PROMOTION_POST_LIST_SUCCESS,
  PROMOTION_POST_LIST_FAIL,
  TIP_POST_LIST_REQUEST,
  TIP_POST_LIST_SUCCESS,
  TIP_POST_LIST_FAIL,
  POST_DETAIL_REQUEST,
  POST_DETAIL_SUCCESS,
  POST_DETAIL_FAIL,
} from '../constants/postConstants'
import {
  CMS_URL,
} from '../constants/webConstants'

export const getListPromotionPostFromAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PROMOTION_POST_LIST_REQUEST })

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/wp/v2/posts?categories=234` ,
      {
        headers: {
          accept: 'application/json',
        },
      }
    )

    dispatch({
      type: PROMOTION_POST_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    dispatch({
      type: PROMOTION_POST_LIST_FAIL,
      payload: message,
    })
  }
}

export const getListTipPostFromAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TIP_POST_LIST_REQUEST })

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/wp/v2/posts?categories=106`,
      {
        headers: {
          accept: 'application/json',
        },
      }
    )

    dispatch({
      type: TIP_POST_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    dispatch({
      type: TIP_POST_LIST_FAIL,
      payload: message,
    })
  }
}

export const getPostDetailsFromAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: POST_DETAIL_REQUEST })

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/wp/v2/posts/${id}`,
      {
        headers: {
          accept: 'application/json',
        },
      }
    )


    // Gọi API lấy feature image của post có featured_media
    if(data.featured_media!==0){
      const { data: mediaData } = await axios.get(
        `${CMS_URL}/wp-json/wp/v2/media/${data.featured_media}`,
        {
          headers: {
            accept: 'application/json',
          },
        }
      )
      //Thêm URL của feature image vào data
      data['featured_media_url'] = mediaData.source_url
    }

    dispatch({
      type: POST_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    dispatch({
      type: POST_DETAIL_FAIL,
      payload: message,
    })
  }
}