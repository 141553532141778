import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        position: "relative",
        margin: "auto",
        display: "block",
        color: "#fff",
      }}
    >
      <img
        src={"/images/TKH-loading.svg"}
        alt="loading"
        width={120}
        height={82}
        className="rotating"
      />
    </div>
  );
};

export default Loader;
