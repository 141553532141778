import axios from 'axios'
import { CART_CLEAR_ITEMS } from '../constants/cartConstants'
import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_REQUEST,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_FAIL,
  COUPON_DETAILS_FAIL,
  COUPON_DETAILS_SUCCESS,
  COUPON_DETAILS_REQUEST,
} from '../constants/orderConstants'
import {
  CMS_URL,
} from '../constants/webConstants'
import { logout } from './userActions'

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    })

    const {
      userInfoFromStore: { userInfoData },
    } = getState();

    let config ={};

    if(userInfoData){//KH đã đăng nhập -> Tạo đơn hàng dùng Wordpress Customer (KH cần đăng ký tài khoản)
      // Việc kiểm tra KH có quyền tạo đơn hàng hay không được thực hiện ở backend -> hàm check_token_for_wc_order_api
      // Xác thực Woocommerce bằng Basic Auth (được tạo và lưu vào userInfoData.data khi user đăng nhập)
      // Cộng với kiểm tra JWT có hết hạn không -> Nếu hết hạn thì redirect về trang login
      config = {
        headers: {
          accept: 'application/json',
          Authorization: userInfoData.data.basic_auth,
          token: `Bearer ${userInfoData.data.token}`,
        },
      }
    }else{//KH chưa đăng ký -> Tạo đơn hàng dùng Woocommerce Customer/Wordpress Guest
      config = {
        headers: {
          accept: 'application/json',
        },
      }
    }

    const { data } = await axios.post(`${CMS_URL}/wp-json/wc/v3/orders`, order, config)

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: CART_CLEAR_ITEMS,
      payload: data,
    })

    //sau khi đặt hàng thành công thì remove các đối tượng lưu trong localStorage
    localStorage.removeItem('cartItemsFromStorage')
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    //nếu token hết hạn hoặc token không hợp lệ thì gọi hàm logout (xoá đối tượng userInfoData)
    if (message === 'Sorry, you are not allowed to create resources.' || message === 'expired_token' || message === 'signature_verification_failed' ) {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: message,
    })
  }
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    })

    const {
      userInfoFromStore: { userInfoData },
    } = getState();

    let config ={};

    if(userInfoData){//KH đã đăng nhập -> Xem đơn hàng dùng Wordpress Customer (KH cần đăng ký tài khoản)
      // Việc kiểm tra KH có quyền tạo đơn hàng hay không được thực hiện ở backend -> hàm check_token_for_wc_order_api
      // Xác thực Woocommerce bằng Basic Auth (được tạo và lưu vào userInfoData.data khi user đăng nhập)
      // Cộng với kiểm tra JWT có hết hạn không -> Nếu hết hạn thì redirect về trang login
      config = {
        headers: {
          accept: 'application/json',
          Authorization: userInfoData.data.basic_auth,
          token: `Bearer ${userInfoData.data.token}`,
        },
      }
    }else{//KH chưa đăng ký -> Xem đơn hàng dùng Woocommerce Customer/Wordpress Guest
      config = {
        headers: {
          accept: 'application/json',
        },
      }
    }

    const { data } = await axios.get(`${CMS_URL}/wp-json/wc/v3/orders/${id}`, config)

    //Lặp qua các mục trong line_items và lấy slug của sản phẩm bằng API
    const fetchProductSlug = async (lineItem) => {
      try {
        const { data: dataProduct } = await axios.get(
          `${CMS_URL}/wp-json/wc/v3/products/${lineItem.product_id}`,
          {
            headers: {
              accept: 'application/json',
            },
          }
        );
    
        // Thêm slug vào danh sách line_items của data trả về
        lineItem['slug'] = dataProduct.slug;
      } catch (error) {
        // Xử lý lỗi ở đây nếu cần
        console.error(error);
      }
    };
    
    // Sử dụng vòng lặp for...of để đợi các yêu cầu axios hoàn thành để tránh bị bất đồng bộ (vòng lặp chạy xong mới gọi hàm dispatch bên dưới)
    for (const lineItem of data.line_items) {
      await fetchProductSlug(lineItem);
    }

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    })
    
  } catch (error) {
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    //nếu token hết hạn hoặc token không hợp lệ thì gọi hàm logout (xoá đối tượng userInfoData)
    if (message === 'expired_token' || message === 'signature_verification_failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const updateOrderStatus = (id, status) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    })

    const {
      userInfoFromStore: { userInfoData },
    } = getState();

    let config ={};

    if(userInfoData){//KH đã đăng nhập -> Xem đơn hàng dùng Wordpress Customer (KH cần đăng ký tài khoản)
      // Việc kiểm tra KH có quyền tạo đơn hàng hay không được thực hiện ở backend -> hàm check_token_for_wc_order_api
      // Xác thực Woocommerce bằng Basic Auth (được tạo và lưu vào userInfoData.data khi user đăng nhập)
      // Cộng với kiểm tra JWT có hết hạn không -> Nếu hết hạn thì redirect về trang login
      config = {
        headers: {
          accept: 'application/json',
          Authorization: userInfoData.data.basic_auth,
          token: `Bearer ${userInfoData.data.token}`,
        },
      }
    }else{//KH chưa đăng ký -> Xem đơn hàng dùng Woocommerce Customer/Wordpress Guest
      config = {
        headers: {
          accept: 'application/json',
        },
      }
    }

    const { data } = await axios.put(`${CMS_URL}/wp-json/wc/v3/orders/${id}?status=${status}`,null, config)

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
    error.response && error.response.message
      ? error.response.message
      : "Lỗi không xác định"
    //nếu token hết hạn hoặc token không hợp lệ thì gọi hàm logout (xoá đối tượng userInfoData)
    if (message === 'expired_token' || message === 'signature_verification_failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const listMyOrders = (userid) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    })

    const {
      userInfoFromStore: { userInfoData },
    } = getState();

    // Việc kiểm tra KH có quyền tạo đơn hàng hay không được thực hiện ở backend -> hàm check_token_for_wc_order_api
    // Xác thực Woocommerce bằng Basic Auth (được tạo và lưu vào userInfoData.data khi user đăng nhập)
    // Cộng với kiểm tra JWT có hết hạn không -> Nếu hết hạn thì redirect về trang login
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: userInfoData.data.basic_auth,
        token: `Bearer ${userInfoData.data.token}`,
      },
    }

    const { data } = await axios.get(`${CMS_URL}/wp-json/wc/v3/orders?customer=${userid}`, config)

    dispatch({
      type: ORDER_LIST_MY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    //nếu token hết hạn hoặc token không hợp lệ thì gọi hàm logout (xoá đối tượng userInfoData)
    if (message === 'expired_token' || message === 'signature_verification_failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload: message,
    })
  }
}

export const getCouponDetailsByCode = (code) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COUPON_DETAILS_REQUEST,
    })

    const {
      userInfoFromStore: { userInfoData },
    } = getState();

    let config ={};

    config = {
      headers: {
        accept: 'application/json',
        // Authorization: userInfoData.data.basic_auth,
        // token: `Bearer ${userInfoData.data.token}`,
      },
    }
    
    const { data } = await axios.get(`${CMS_URL}/wp-json/kojiesan/v2/getcoupondetailbycode/${code}`, config)

    dispatch({
      type: COUPON_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    //nếu token hết hạn hoặc token không hợp lệ thì gọi hàm logout (xoá đối tượng userInfoData)
    if (message === 'expired_token' || message === 'signature_verification_failed') {
      dispatch(logout())
    }
    dispatch({
      type: COUPON_DETAILS_FAIL,
      payload: message,
    })
  }
}