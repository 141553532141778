import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { initPasswordWithOTP } from "../actions/userActions";

const InitPasswordWithOTPScreen = ({ location, history }) => {
  const [otp, setOtp] = useState("");
  const [pass, setPass] = useState("");
  const [confirm_pass, setConfirm_pass] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const userInitPasswordWithOTPFromScreen = useSelector(
    (state) => state.userInitPasswordWithOTPFromStore
  );
  const { loading, error, userInitPasswordWithOTPData } =
    userInitPasswordWithOTPFromScreen;

  //const redirect = location.search ? location.search.split('=')[1] : '/'

  const username = new URLSearchParams(location.search).get("username");

  const redirect = "/login";

  useEffect(() => {
    if (userInitPasswordWithOTPData) {
      history.push(redirect);
    }
  }, [history, userInitPasswordWithOTPData, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (pass !== confirm_pass) {
      setMessage("Mật khẩu chưa trùng khớp");
    } else {
      dispatch(initPasswordWithOTP(username, otp, pass, confirm_pass));
    }
  };

  return (
    <div className="bg-red">
      <Container
        style={{
          minHeight: 800,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && (
          <div className="loading-full">
            <Loader />
          </div>
        )}
        <div className="box-login">
          <h1 className="titleSlide h3 mb-4">Khởi tạo mật khẩu</h1>
          <p className="text-center mb-3">
            Mã OTP được gửi SMS đến số {username}
          </p>
          {error && <Message variant="danger">{error}</Message>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="otp">
              <Form.Label className="titleProduct mx-1">Mã OTP:</Form.Label>
              <Form.Control
                type="otp"
                placeholder="Nhập OPT"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="pass">
              <Form.Label className="titleProduct mx-1">Mật khẩu:</Form.Label>
              <Form.Control
                type="password"
                placeholder="Nhập mật khẩu"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="confirm_pass">
              <Form.Label className="titleProduct mx-1">
                Mật khẩu nhập lại:
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Nhập lại mật khẩu"
                value={confirm_pass}
                onChange={(e) => setConfirm_pass(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type="submit" className="btn btn-red w-100">
              Tạo mật khẩu
            </Button>
          </Form>

          <hr className="my-3"></hr>
          <Row className="py-3">
            <Col>
              <p className="text-center mb-3">Bạn đã có tài khoản?</p>
              <Link
                className="btn btn-red"
                to={redirect ? `/login?redirect=${redirect}` : "/login"}
              >
                Đăng nhập
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default InitPasswordWithOTPScreen;
