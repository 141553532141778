import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  GUEST_INFO_SUCCESS,
  CHECK_OTP_TO_ORDER_REQUEST,
  CHECK_OTP_TO_ORDER_SUCCESS,
  CHECK_OTP_TO_ORDER_FAIL,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_WITH_ORDER_FAIL,
  USER_REGISTER_WITH_ORDER_SUCCESS,
  USER_INIT_PASSWORD_WITH_OTP_REQUEST,
  USER_INIT_PASSWORD_WITH_OTP_SUCCESS,
  GET_OTP_RESET_PASS_FAIL,
  USER_RESET_PASSWORD_WITH_OTP_FAIL,
  USER_RESET_PASSWORD_WITH_OTP_REQUEST,
  USER_RESET_PASSWORD_WITH_OTP_SUCCESS,
  USER_INIT_PASSWORD_WITH_OTP_FAIL,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_RESET,
  CUSTOMER_POINTS_AND_MEMBERSHIP_SUCCESS,
  CUSTOMER_LIST_POINT_REQUEST,
  CUSTOMER_LIST_POINT_SUCCESS,
  CUSTOMER_LIST_POINT_FAIL,
} from '../constants/userConstants'

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true }
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfoData: action.payload }
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case USER_REGISTER_WITH_ORDER_FAIL:
      return { loading: false, message: action.payload.message }
    case USER_REGISTER_WITH_ORDER_SUCCESS:
      return { loading: false, message: action.payload.message }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userInitPasswordWithOTPReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_INIT_PASSWORD_WITH_OTP_REQUEST:
      return { loading: true }
    case USER_INIT_PASSWORD_WITH_OTP_SUCCESS:
      return { loading: false, userInitPasswordWithOTPData: action.payload }
    case USER_INIT_PASSWORD_WITH_OTP_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userResetPasswordWithOTPReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_OTP_RESET_PASS_FAIL:
      return { loading: false, error: action.payload }
    case USER_RESET_PASSWORD_WITH_OTP_REQUEST:
      return { loading: true }
    case USER_RESET_PASSWORD_WITH_OTP_SUCCESS:
      return { loading: false, userResetPasswordWithOTPData: action.payload }
    case USER_RESET_PASSWORD_WITH_OTP_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      /*
      sau khi login thành công thì lưu thông tin user vào đối tượng userInfoData trong store.js
      */
      return { loading: false, userInfoData: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const guestInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case GUEST_INFO_SUCCESS:
      return { guestInfoData: { display_name: action.payload.display_name, phone: action.payload.phone, pass: action.payload.pass} }
    case CHECK_OTP_TO_ORDER_SUCCESS:
      return { loading: false, guestInfoData: action.payload }
    case CHECK_OTP_TO_ORDER_FAIL:
      return { loading: false, error: action.payload, guestInfoData: JSON.parse(localStorage.getItem('guestInfoFromStorage')) }
    default:
      return state
  }
}

export const customerPointsAndMembershipReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_POINTS_AND_MEMBERSHIP_SUCCESS://Sau khi API getcustomerpointsandmembership được gọi
      return { points: action.payload.points, membership: action.payload.membership, success:true}
    default:
      // Kiểm tra nếu tồn tại customerPointsAndMembershipFromStorage trong localStorage
      // thì gán points-listpoint-membership và redeempoints-listredeempoint từ customerPointsAndMembershipFromStorage
      const customerPointsAndMembershipFromStorage = localStorage.getItem('customerPointsAndMembershipFromStorage');
      return customerPointsAndMembershipFromStorage
        ? { points: JSON.parse(customerPointsAndMembershipFromStorage).points, listpoint: JSON.parse(customerPointsAndMembershipFromStorage).listpoint, 
          membership: JSON.parse(customerPointsAndMembershipFromStorage).membership, redeempoints: JSON.parse(customerPointsAndMembershipFromStorage).redeempoints, 
          listredeempoint: JSON.parse(customerPointsAndMembershipFromStorage).listredeempoint,  success:true}
        : state;
  }
}

export const userDetailsReducer = (state = { userDetailsData: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true }
    case USER_DETAILS_SUCCESS:
      return { loading: false, userDetailsData: action.payload }
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case USER_DETAILS_RESET:
      return { userDetailsData: {} }
    default:
      return state
  }
}

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfoData: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const customerListPointReducer = (state = { customerListPointData: {} }, action) => {
  switch (action.type) {
    case CUSTOMER_LIST_POINT_REQUEST:
      return { ...state, loading: true }
    case CUSTOMER_LIST_POINT_SUCCESS:
      return { loading: false, customerListPointData: action.payload }
    case CUSTOMER_LIST_POINT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

