import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_INFO,
  CART_CLEAR_ITEMS,
} from '../constants/cartConstants'

export const cartReducer = (
  state = { cartItemsData: [] },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload

      const existItem = state.cartItemsData.find((x) => x.product_id === item.product_id)

      if (existItem) {
        localStorage.setItem('cartItemsFromStorage', JSON.stringify(state.cartItemsData.map((x) =>
        x.product_id === existItem.product_id ? item : x)))
        return {
          ...state,
          cartItemsData: state.cartItemsData.map((x) =>
            x.product_id === existItem.product_id ? item : x
          ),
        }
      } else {
        localStorage.setItem('cartItemsFromStorage', JSON.stringify([...state.cartItemsData, item]))
        return {
          ...state,
          cartItemsData: [...state.cartItemsData, item],
        }
      }
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItemsData: state.cartItemsData.filter((x) => x.product_id !== action.payload),
      }
    case CART_SAVE_SHIPPING_INFO:
      return {//khi thực hiện xong hàm này thì đối tượng cartFromStore sẽ chứa các phần tử cartItemsData, shippingAddressData, paymentMethodData, orderNoteData
        ...state, //state = { cartItemsData: [] } là tham biến được định nghĩa ở đầu hàm
        shippingAddressData: action.payload.address,
        paymentMethodData: action.payload.paymentMethod,
        orderNoteData: action.payload.orderNote,
      }
    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItemsData: [],
      }
    default:
      return state
  }
}
