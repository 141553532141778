import React, { useState } from 'react'

const TextCollapse = ({ text, maxLength }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // Kiểm tra xem text có phải là chuỗi không
  if (typeof text !== 'string') {
    return null; // Hoặc thể hiện một thông báo lỗi
  }

  const displayText = expanded ? text : text.slice(0, maxLength);

  return (
    <div>
      <p>{<div dangerouslySetInnerHTML={{ __html: displayText }} />}</p>
      {text.length > maxLength && !expanded && (
        <button className="btn btn-red" onClick={toggleExpanded}>
          Xem thêm
        </button>
      )}
      {expanded && (
        <div>
          <p>{text}</p>
          <button className="btn btn-red" onClick={toggleExpanded}>
            Thu gọn
          </button>
        </div>
      )}
    </div>
  )
}
export default TextCollapse
