import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
} from "../constants/userConstants";
import { getCustomerPointsAndMembership } from '../actions/userActions'

const AutoRequestServer = ({userid}) => {

  const dispatch = useDispatch();

  useEffect(() => {
    // Thiết lập thời gian định kỳ là 1 phút (60000 milliseconds)
    const interval = setInterval(() => {
      // Gọi action để fetch dữ liệu từ server
      dispatch(getCustomerPointsAndMembership(userid));
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div></div>
  )
}

export default AutoRequestServer
