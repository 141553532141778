import React, { useState, useEffect, useContext } from "react";
import { Form, Col, Button, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { saveShippingInfo, getOrderOTP } from "../actions/cartActions";
import Message from "../components/Message";
//Import LanguageContext được export từ App.js
import { LanguageContext } from "../App";

const ShippingScreen = ({ history, location }) => {
  /*CHÚ Ý QUAN TRỌNG
  - Sau khi login thành công thì lưu thông tin user vào đối tượng userInfoData (ở hàm userReducer.js -> userLoginReducer)
  - Đối tượng này được định nghĩa trong store.js
  - Đối tượng này sẽ mất đi khi chuyển/refesh trang 
  -> Để sử dụng đối tượng userInfoData trong các màn hình mà không cần dispatch lại API userLogin 
  -> Thì trong store.js sử dụng Local storage để lưu đối tượng userInfoFromStorage
      const userInfoFromStorage = localStorage.getItem('userInfoFromStorage')
      ? JSON.parse(localStorage.getItem('userInfoFromStorage'))
      : null
  - Sau đó hàm initialState sẽ gán đối tượng Local Storage userInfoFromStorage vào các đối tượng trong userInfoData
    const initialState = {
      cartFromStore: {
        cartItemsData: cartItemsFromStorage,
      },
      userLoginFromStore: { userInfoData: userInfoFromStorage },
      messageSettingsFromStore: messageSettingsFromStorage,
    }
  - Đối tượng này sẽ được sử dụng để kiểm tra user đã đăng nhập chưa và được sử dụng trong các components dùng chung như Header.js
  - Khác với đối tượng userInfoData (ở hàm userReducer.js -> userRegisterReducer) được dùng trong RegisterScreen
*/
  const userLoginFromScreen = useSelector((state) => state.userInfoFromStore);
  const { userInfoData } = userLoginFromScreen;

  const cart = useSelector((state) => state.cartFromStore);
  const { shippingAddressData, paymentMethodData, orderNoteData } = cart;

  const guestInfoFromScreen = useSelector((state) => state.guestInfoFromStore);
  const { guestInfoData } = guestInfoFromScreen;

  const [display_name, setDisplay_name] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  // const [email, setEmail] = useState('');
  const [pass, setPass] = useState("");
  const [showPassTextbox, setShowPassTextbox] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const [orderNote, setOrderNote] = useState("");
  const [message, setMessage] = useState("");

  //Biến kiểm tra KH có thao tác cập nhật không để hiển thị message
  const [isUpdateShipping, setIsUpdateShipping] = useState(false);

  //Lấy ngôn ngữ được chọn có phải tiếng Việt không từ biến dùng chung isVietnamese trong LanguageContext được khai báo trong App.js
  const { isVietnamese, setIsVietnamese } = useContext(LanguageContext);
  //messageSettingData chứa thông tin message như Trang chủ, Sản phẩm... cho các menu và label
  const messageSettingsFromScreen = useSelector(
    (state) => state.messageSettingsFromStore
  );
  const { messageSettingData } = messageSettingsFromScreen;

  const dispatch = useDispatch();

  useEffect(() => {
    //KH đã đăng nhập hay không đều có quyền đặt hàng
    if (!userInfoData) {
      //Nếu KH chưa đăng nhập và đã mua hàng trước đó -> Lấy thông tin từ guestInfoFromStore, shippingAddressData, orderNoteData
      setDisplay_name(guestInfoData ? guestInfoData.display_name : "");
      // setEmail(guestInfoFromStore != null ? guestInfoFromStore.email : '')
      setPhone(guestInfoData ? guestInfoData.phone : "");
      setAddress(
        typeof shippingAddressData !== "object" ? shippingAddressData : ""
      );
      setPaymentMethod(
        typeof paymentMethodData !== "object" ? paymentMethodData : "COD"
      );
      setOrderNote(orderNoteData ? orderNoteData : "");
      //Hiển thị thông báo lỗi trên trang shipping nếu có
      const urlParams = new URLSearchParams(window.location.search);
      setMessage(urlParams.get("message"));
    } else if (typeof shippingAddressData !== "object") {
      //KH đã đăng nhập và trước đó đã nhập địa chỉ giao hàng thì typeof shippingAddressData !== 'object' -> gán địa chỉ giao hàng = shippingAddressData
      setDisplay_name(userInfoData.data.display_name);
      setPhone(userInfoData.data.username);
      setAddress(shippingAddressData);
      // setEmail(userInfoData.data.email);
      setPaymentMethod(
        typeof paymentMethodData !== "object" ? paymentMethodData : "COD"
      );
      setOrderNote(orderNoteData ? orderNoteData : "");
    } else {
      //nếu đã đăng nhập và vào trang shipping thì hiển thị thông tin từ userInfoFromStore -> userInfoData
      setDisplay_name(userInfoData.data.display_name);
      setPhone(userInfoData.data.username);
      setAddress(userInfoData.data.shipping_address_1);
      // setEmail(userInfoData.data.email);
      setPaymentMethod(
        typeof paymentMethodData !== "object" ? paymentMethodData : "COD"
      );
      setOrderNote(orderNoteData ? orderNoteData : "");
    }
    //Gán biến message bằng tham số message trên URL (nếu có)
    setMessage(new URLSearchParams(location.search).get("message"));
  }, [
    dispatch,
    userInfoData,
    guestInfoData,
    shippingAddressData,
    paymentMethodData,
    orderNoteData,
    isUpdateShipping,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      saveShippingInfo({
        display_name,
        phone,
        pass,
        address,
        paymentMethod,
        orderNote,
      })
    );
    //Nếu KH chưa đăng nhập thì gửi OTP xác nhận đặt hàng
    if (!userInfoData) {
      dispatch(getOrderOTP(phone, history));
    } else {
      //Nếu KH đã đăng nhập thì hiển thị màn hình đặt hàng
      history.push("/placeorder");
    }
    // Cập nhật lại biến isUpdateShipping để hiển thị message (nếu có)
    setIsUpdateShipping(true);
  };

  const handleCheckboxChange = (event) => {
    setShowPassTextbox(event.target.checked);
  };

  return (
    <div className="bg-red">
      <Container
        style={{
          minHeight: 900,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="box-login" style={{ maxWidth: 800 }}>
          <CheckoutSteps step1 />
          <h1 className="titleSlide h3 mb-4">Thông tin giao hàng</h1>
          {messageSettingData && message && (
            <Message variant="danger">
              {isVietnamese
                ? JSON.parse(messageSettingData[0].vietnamese)[message]
                : JSON.parse(messageSettingData[0].english)[message]}
            </Message>
          )}
          <Form onSubmit={submitHandler}>
            <Row>
              <Col md>
                <Form.Group controlId="display_name">
                  <Form.Label className="titleProduct mx-1">
                    Họ và tên
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nhập số họ tên"
                    value={display_name}
                    required
                    onChange={(e) => setDisplay_name(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group controlId="phone">
                  <Form.Label className="titleProduct mx-1">
                    Điện thoại
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nhập số điện thoại"
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="address">
              <Form.Label className="titleProduct mx-1">
                Địa chỉ nhận hàng
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Nhập địa chỉ nhận hàng"
                value={address}
                required
                onChange={(e) => setAddress(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label className="titleProduct mx-1">
                Hình thức thanh toán
              </Form.Label>
              <Col>
                <Form.Check
                  type="radio"
                  label="Thanh toán khi nhận hàng"
                  id="COD"
                  name="paymentMethod"
                  value="COD"
                  checked={paymentMethod === "COD"}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>
                <Form.Check
                  type="radio"
                  label="Chuyển khoản"
                  id="BankTransfer"
                  name="paymentMethod"
                  value="BankTransfer"
                  checked={paymentMethod === "BankTransfer"}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>
              </Col>
            </Form.Group>
            <Form.Group controlId="note">
              <Form.Label className="titleProduct mx-1">Ghi chú</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nhập ghi chú"
                value={orderNote}
                onChange={(e) => setOrderNote(e.target.value)}
              ></Form.Control>
            </Form.Group>
            {!userInfoData && ( //Nếu KH chưa đăng ký thì hiển thị chức năng tạo tài khoản
              <Form.Group controlId="pass">
                <label className="mx-3">
                  <input
                    type="checkbox"
                    checked={showPassTextbox}
                    onChange={handleCheckboxChange}
                  />
                  <span className="mx-2">Tạo tài khoản</span>
                </label>
                {showPassTextbox && (
                  <>
                    <Form.Label className="titleProduct mx-1">
                      Mật khẩu
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nhập mật khẩu cần tạo"
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                    ></Form.Control>
                  </>
                )}
              </Form.Group>
            )}
            <Button
              className="btn btn-red w-100"
              type="submit"
              variant="primary"
            >
              Tiếp tục
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default ShippingScreen;
