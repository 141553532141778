import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  getOrderDetails,
} from '../actions/orderActions'

const OrderDetailScreen = ({ match, history }) => {
  const orderId = match.params.id

  const dispatch = useDispatch()

  const orderDetailsFromScreen = useSelector((state) => state.orderDetailsFromStore)
  const { order, loading, error } = orderDetailsFromScreen

  //Lấy tổng tiền sản phẩm
  const [totalItemsPrice, setTotalItemsPrice] = useState(0);

  //Lấy thông tin shipping_fee từ appSettingsFromStore
  const appSettingsFromScreen = useSelector((state) => state.appSettingsFromStore)
  const { shipping_fee } = appSettingsFromScreen

  useEffect(() => {
    if (!order) {
      dispatch(getOrderDetails(orderId))
    }else{//Lấy tổng tiền sản phẩm
      setTotalItemsPrice(order.line_items.reduce((total, item) => total + item.quantity * item.price,0));
    }
  }, [dispatch, orderId, order])

  return loading ? (
    <div className="loading-full">
      <Loader />
    </div>
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h4>Chi tiết đơn hàng {order.id}</h4>
              {/* Hiển thị thông tin cá nhân nếu đặt hàng có đăng nhập */}
              {order.customer_id !== 0 && (
                <>
                  <p>Họ và tên: {order.billing.first_name}</p>
                  <p>Địa chỉ nhận hàng: {order.billing.address_1}</p>
                  <p>Số điện thoại: {order.billing.phone}</p>
                </>
              )}
              <p>
                Trạng thái đơn hàng:
                {order.status === 'completed' ? (
                  <>
                    Giao hàng thành công {order.date_completed}
                  </>
              ) : order.status === 'on-hold'?(
                'Chờ tiếp nhận'
              ) : order.status === 'processing'?(
                'Đang giao hàng'
              ) : order.status === 'received'?(
                'Đã nhận hàng'
              ):
              (
                <Message variant='danger'>Vui lòng liên hệ shop</Message>
              )}
              </p>
              <p>Ghi chú: {order.customer_note}</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>Trạng thái thanh toán: </strong> {order.payment_method === 'BankTransfer' ? 'Chuyển khoản': order.payment_method === 'COD' ? 'Thanh toán khi nhận hàng':'Vui lòng chọn hình thức thanh toán'}
                {order.date_completed != null ? (
                  <>
                    (Đã thanh toán {order.date_paid})
                  </>
                ) : (
                  ' (Chưa thanh toán)'
                )}
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>Danh sách sản phẩm</strong>
              {order.line_items.length === 0 ? (
                <Message>Đơn hàng chưa có sản phẩm</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.line_items.map((item, index) => (              
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image.src}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.slug}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {/* Nếu có coupon và coupon là freeship */}
                          {order.coupon_lines && order.coupon_lines[0] && order.coupon_lines[0].meta_data[0] && order.coupon_lines[0].meta_data[0].value.free_shipping && (
                            <>
                            {item.quantity} x {parseInt(item.price).toLocaleString("vi", {
                            style: "currency",
                            currency: "VND",
                            })} = {parseInt(item.quantity * item.price).toLocaleString("vi", {
                              style: "currency",
                              currency: "VND",
                            })}
                            </>
                          )}
                          {/* Nếu có coupon và coupon là fixed_cart) */}
                          {order.coupon_lines && order.coupon_lines[0] && order.coupon_lines[0].meta_data[0] && !order.coupon_lines[0].meta_data[0].value.free_shipping && (
                            <>
                            {item.quantity} x {(parseInt(item.subtotal)/parseInt(item.quantity)).toLocaleString("vi", {
                                                            style: "currency",
                                                            currency: "VND",
                                                            })} = {parseInt(item.subtotal).toLocaleString("vi", {
                                                            style: "currency",
                                                            currency: "VND",
                                                            })}
                            </>
                          )}
                          {/* Nếu không có coupon */}
                          {order.coupon_lines.length === 0 && (
                            <>
                            {item.quantity} x {parseInt(item.price).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })} = {parseInt(item.quantity * item.price).toLocaleString("vi", {
                                                    style: "currency",
                                                    currency: "VND",
                                                    })}
                            </>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h4>Tổng hợp đơn hàng</h4>
              </ListGroup.Item>
              {/* Nếu có coupon và coupon là freeship */}
              {order.coupon_lines && order.coupon_lines[0] && order.coupon_lines[0].meta_data[0] && order.coupon_lines[0].meta_data[0].value.free_shipping && (
                <>
                <ListGroup.Item>
                  <Row>
                    <Col>Tiền sản phẩm</Col>
                    <Col>{parseInt(totalItemsPrice).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Phí vận chuyển</Col>
                    <Col>{parseInt(shipping_fee).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Khuyến mãi <span>{'\n'}</span> ({order.coupon_lines[0].meta_data[0].value.description})</Col>
                    <Col>- {parseInt(shipping_fee).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                  </Row>
                </ListGroup.Item>
                </>
              )}
              {/* Nếu có coupon và coupon là fixed_cart) */}
              {order.coupon_lines && order.coupon_lines[0] && order.coupon_lines[0].meta_data[0] && !order.coupon_lines[0].meta_data[0].value.free_shipping && (
                <>
                <ListGroup.Item>
                  <Row>
                    <Col>Tiền sản phẩm</Col>
                    <Col>{(parseInt(order.total) + parseInt(order.coupon_lines[0].discount) - parseInt(order.shipping_total)).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>

                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Phí vận chuyển</Col>
                    <Col>{parseInt(order.shipping_total).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Khuyến mãi <span>{'\n'}</span> ({order.coupon_lines[0].meta_data[0].value.description})</Col>
                    <Col>- {parseInt(order.coupon_lines[0].discount).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                  </Row>
                </ListGroup.Item>
                </>
              )}
              {/* Nếu có đổi điểm) */}
              {order.fee_lines && order.fee_lines[0] && order.fee_lines[0].id && (
                <>
                <ListGroup.Item>
                  <Row>
                    <Col>Tiền sản phẩm</Col>
                    <Col>{parseInt(totalItemsPrice).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Phí vận chuyển</Col>
                    <Col>{parseInt(order.shipping_total).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Đổi {order.fee_lines[0].name} điểm</Col>
                    <Col>{parseInt(order.fee_lines[0].total).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                  </Row>
                </ListGroup.Item>
                </>
              )}
              {/* Nếu không có coupon */}
              {order.coupon_lines.length === 0 && order.fee_lines.length === 0 &&  (
                <>
                <ListGroup.Item>
                  <Row>
                    <Col>Tiền sản phẩm</Col>
                    <Col>{parseInt(totalItemsPrice).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Phí vận chuyển</Col>
                    <Col>{parseInt(order.shipping_total).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                  </Row>
                </ListGroup.Item>
                </>
              )}
              <ListGroup.Item>
                <Row>
                  <Col>Tổng tiền</Col>
                  <Col>{parseInt(order.total).toLocaleString("vi", {
                                                  style: "currency",
                                                  currency: "VND",
                                                  })}</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default OrderDetailScreen
