import React, { useState, useContext } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { checkOrderOTP } from "../actions/userActions";
//Import LanguageContext được export từ App.js
import { LanguageContext } from '../App';

const OrderOTPScreen = ({ history }) => {
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();

  const guestInfoFromScreen = useSelector((state) => state.guestInfoFromStore);
  const { loading, error, guestInfoData } = guestInfoFromScreen;

  //Lấy ngôn ngữ được chọn có phải tiếng Việt không từ biến dùng chung isVietnamese trong LanguageContext được khai báo trong App.js
  const { isVietnamese, setIsVietnamese } = useContext(LanguageContext);

  // //messageSettingData chứa thông tin message như Trang chủ, Sản phẩm... cho các menu và label
  const messageSettingsFromScreen = useSelector((state) => state.messageSettingsFromStore);
  const { messageSettingData } = messageSettingsFromScreen;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(checkOrderOTP(guestInfoData.phone, otp, history));
  };

  return (
    <div className="bg-red">
      <Container
        style={{
          minHeight: 450,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="box-login">
          {guestInfoData && guestInfoData.pass !== "" && (
            <h1 className="titleSlide h5 mb-4">
              Xác nhận OTP đăng ký tài khoản và đặt hàng
            </h1>
          )}
          {guestInfoData && guestInfoData.pass === "" && (
            <h1 className="titleSlide h5 mb-4">Xác nhận OTP đặt hàng</h1>
          )}
          {error && messageSettingData && <Message variant="danger">{isVietnamese ? JSON.parse(messageSettingData[0].vietnamese)[error] : JSON.parse(messageSettingData[0].english)[error]}</Message>}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="otp">
              <Form.Label className="titleProduct mx-1">
                OTP được gửi qua SMS:
              </Form.Label>
              <Form.Control
                type="otp"
                placeholder="Nhập OPT"
                required
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button className="btn btn-red" type="submit" variant="primary">
              Xác nhận
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default OrderOTPScreen;
