import axios from 'axios'
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_BESTSELLER_LIST_REQUEST,
  PRODUCT_BESTSELLER_LIST_SUCCESS,
  PRODUCT_BESTSELLER_LIST_FAIL,
  PRODUCT_SALE_LIST_REQUEST,
  PRODUCT_SALE_LIST_SUCCESS,
  PRODUCT_SALE_LIST_FAIL,
  PRODUCT_BY_CATID_LIST_REQUEST,
  PRODUCT_BY_CATID_LIST_SUCCESS,
  PRODUCT_BY_CATID_LIST_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  APP_SETTINGS_LIST_SUCCESS,
  MESSAGE_SETTINGS_LIST_SUCCESS,
} from '../constants/productConstants'
import {
  CMS_URL,
} from '../constants/webConstants'
import { logout } from './userActions'

export const listProductsFromAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST })

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/wc/v3/products?per_page=100&orderby=menu_order&order=desc`,
      {
        headers: {
          accept: 'application/json',
          // KH không cần đăng nhập vẫn xem API này -> không cần check token
          // token: `Bearer ${userInfoData.data.token}`,
          // Authorization: 'Basic a29qaWVzYW46S29qaWVzYW5AMjAyMg==',
        },
      }
    )

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload: message,
    })
  }
}

export const listBestSellerProductsFromAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_BESTSELLER_LIST_REQUEST })

    // const {
    //   userInfoFromStore: { userInfoData },
    // } = getState();

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/wc/v3/products?category=233&per_page=100&orderby=menu_order&order=desc`,
      {
        headers: {
          accept: 'application/json',
          // KH không cần đăng nhập vẫn xem API này -> không cần check token
          // token: `Bearer ${userInfoData.data.token}`,
          // Authorization: 'Basic a29qaWVzYW46S29qaWVzYW5AMjAyMg==',
        },
      }
    )

    dispatch({
      type: PRODUCT_BESTSELLER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    dispatch({
      type: PRODUCT_BESTSELLER_LIST_FAIL,
      payload: message,
    })
  }
}

export const listSaleProductsFromAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_SALE_LIST_REQUEST })

    // const {
    //   userInfoFromStore: { userInfoData },
    // } = getState();

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/wc/v3/products?category=232&per_page=100&orderby=menu_order&order=desc`,
      {
        headers: {
          accept: 'application/json',
        },
      }
    )

    dispatch({
      type: PRODUCT_SALE_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    dispatch({
      type: PRODUCT_SALE_LIST_FAIL,
      payload: message,
    })
  }
}

export const listProductsByCatIDFromAction = (catID) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_BY_CATID_LIST_REQUEST })

    let data;

    if(catID === 1){//Bỏ lọc theo danh mục
      const response = await axios.get(
        `${CMS_URL}/wp-json/wc/v3/products?per_page=100&orderby=menu_order&order=desc`,
        {
          headers: {
            accept: 'application/json',
          },
        }
      )
      data = response.data;
    }else{//Có lọc theo danh mục
      const response = await axios.get(
        `${CMS_URL}/wp-json/wc/v3/products?category=${catID}&per_page=100`,
        {
          headers: {
            accept: 'application/json',
          },
        }
      )
      data = response.data;
    }

    dispatch({
      type: PRODUCT_BY_CATID_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    dispatch({
      type: PRODUCT_BY_CATID_LIST_FAIL,
      payload: message,
    })
  }
}

export const listProductDetailsFromAction = (slug) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST })

    const {
      userInfoFromStore: { userInfoData },
    } = getState();

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/wc/v3/products?slug=${slug}`,
      {
        headers: {
          accept: 'application/json',
          // KH không cần đăng nhập vẫn xem API này -> không cần check token
          // token: `Bearer ${userInfoData.data.token}`,
          // Authorization: 'Basic a29qaWVzYW46S29qaWVzYW5AMjAyMg==',
        },
      }
    )

    //Lấy danh sách review của sản phẩm đó
    const { data: dataReview } = await axios.get(
      `${CMS_URL}/wp-json/wc/v3/products/reviews?context=view&product=${data[0].id}`,
      {
        headers: {
          accept: 'application/json',
        },
      }
    )

    //Thêm danh sách review  vào data
    data['reviews'] = dataReview

    //Nếu KH đã đăng nhập thì kiểm tra KH đã mua sản phẩm đó chưa để hiển thị phần nhập đánh giá
    if(userInfoData){
      const { data: dataCanReviewProduct } = await axios.post(
        `${CMS_URL}/wp-json/kojiesan/v2/checkwpusercanreviewproduct/${userInfoData.data.userid}/${data[0].id}`,
        {
          headers: {
            accept: 'application/json',
          },
        }
      )
  
      //Thêm biến canReviewProduct vào data
      data['canReviewProduct'] = dataCanReviewProduct
    }
    
    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data[0],
    })
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const createProductReview = (review) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REVIEW_REQUEST,
    })

    const {
      userInfoFromStore: { userInfoData },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: userInfoData.data.basic_auth,
        token: `Bearer ${userInfoData.data.token}`,
      },
    }

    await axios.post(`${CMS_URL}/wp-json/wc/v3/products/reviews`, review, config)
    

    dispatch({
      type: PRODUCT_CREATE_REVIEW_SUCCESS,
    })
  } catch (error) {
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    //nếu token hết hạn hoặc token không hợp lệ thì gọi hàm logout (xoá đối tượng userInfoData)
    if (message === 'expired_token' || message === 'signature_verification_failed') {
      dispatch(logout())
    }
    dispatch({
      type: PRODUCT_CREATE_REVIEW_FAIL,
      payload: message,
    })
  }
}

export const listCategoriesFromAction = () => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_LIST_REQUEST })

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/wc/v3/products/categories`,
      {
        headers: {
          accept: 'application/json',
          // KH không cần đăng nhập vẫn xem API này -> không cần check token
          // token: `Bearer ${userInfoData.data.token}`,
          // Authorization: 'Basic a29qaWVzYW46S29qaWVzYW5AMjAyMg==',
        },
      }
    )

    // Sắp xếp mảng dữ liệu theo thuộc tính 'menu_order'
    data.sort((a, b) => a.menu_order - b.menu_order);

    dispatch({
      type: CATEGORY_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
    dispatch({
      type: CATEGORY_LIST_FAIL,
      payload: message,
    })
  }
}


export const getAppSettings = () => async (dispatch, getState) => {
  try {

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/kojiesan/v2/appsetting?active=1`,
      {
        headers: {
          accept: 'application/json',
        },
      }
    )

    dispatch({
      type: APP_SETTINGS_LIST_SUCCESS,
      payload: data,
    })
    localStorage.setItem('appSettingsFromStorage', JSON.stringify(data))
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
  }
}

export const getMessageSettings = () => async (dispatch, getState) => {
  try {

    const { data } = await axios.get(
      `${CMS_URL}/wp-json/kojiesan/v2/messagesetting?message_active=1`,
      {
        headers: {
          accept: 'application/json',
        },
      }
    )

    dispatch({
      type: MESSAGE_SETTINGS_LIST_SUCCESS,
      payload: data,
    })
    localStorage.setItem('messageSettingsFromStorage', JSON.stringify(data))
  } catch (error) {
    //lỗi khi api trả về status=500
    const message =
    error.response && error.response.data
      ? error.response.data.message
      : "Lỗi không xác định"
  }
}
