import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form } from 'react-bootstrap'
import Rating from '../components/Rating'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { Link } from "react-router-dom"
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import {
  listProductDetailsFromAction,
  createProductReview,
} from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'
import TextCollapse from '../components/TextCollapse'


const ProductScreen = ({ history, match }) => {
  const navigationStyle = {
    color: '#ea0429', // Màu của nút Navigation trong file này
  }
  const [qty, setQty] = useState(1)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const dispatch = useDispatch()

  /*CHÚ Ý QUAN TRỌNG
  - Sau khi login thành công thì lưu thông tin user vào đối tượng userInfoData (ở hàm userReducer.js -> userLoginReducer)
  - Đối tượng này được định nghĩa trong store.js
  - Đối tượng này sẽ mất đi khi chuyển/refesh trang 
  -> Để sử dụng đối tượng userInfoData trong các màn hình mà không cần dispatch lại API userLogin 
  -> Thì trong store.js sử dụng Local storage để lưu đối tượng userInfoFromStorage
      const userInfoFromStorage = localStorage.getItem('userInfoFromStorage')
      ? JSON.parse(localStorage.getItem('userInfoFromStorage'))
      : null
  - Sau đó hàm initialState sẽ gán đối tượng Local Storage userInfoFromStorage vào các đối tượng trong userInfoData
    const initialState = {
      cartFromStore: {
        cartItemsData: cartItemsFromStorage,
      },
      userInfoFromStore: { userInfoData: userInfoFromStorage },
      messageSettingsFromStore: messageSettingsFromStorage,
    }
  - Đối tượng này sẽ được sử dụng để kiểm tra user đã đăng nhập chưa và được sử dụng trong các components dùng chung như Header.js
  - Khác với đối tượng userInfoData (ở hàm userReducer.js -> userRegisterReducer) được dùng trong RegisterScreen
*/
  const userLoginFromScreen = useSelector((state) => state.userInfoFromStore)
  const { userInfoData } = userLoginFromScreen

  const productDetailsFromScreen = useSelector((state) => state.productDetailsFromStore)
  const { loading, error, productDetailData } = productDetailsFromScreen

  const [selectedImageURL, setselectedImageURL] = useState('')

  const productReviewCreateFromScreen = useSelector((state) => state.productReviewCreateFromStore)
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProductReview,
  } = productReviewCreateFromScreen

  useEffect(() => {
    if (successProductReview) {
      setRating(0)
      setComment('')
    }
    if (!productDetailData.id || productDetailData.id !== match.params.id) {
      dispatch(listProductDetailsFromAction(match.params.id))
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    }
  }, [dispatch, match])

  const addToCartHandler = () => {
    history.push(`/cart/${productDetailData.id}?qty=${qty}`)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProductReview({product_id:match.params.id, status:'hold',reviewer: userInfoData.data.display_name,reviewer_email:userInfoData.data.email, review:comment, rating:rating})
    )
  }

  const changeSelectedImage = (imageURL) => {
    setselectedImageURL(imageURL)
  }

  return (
      <>
      {loading ? (
        <div className="loading-full">
          <Loader />
        </div>
      ) : (
      <>
      <div className='container mt-2'>
        <div className='row'>
            {/* Nếu kích thước màn hình >= MD (768-992px) */}
            {productDetailData.images && window.innerWidth >= 768 && (
              <div className='col-md-4 mb-2'>
                {selectedImageURL && (
                  <>
                    <Image
                    src={selectedImageURL}
                    alt={productDetailData.name}
                    fluid
                    className='align-items-center'
                    />
                    {productDetailData.sale_price > 0 && (
                      <div class="sale-icon"></div>
                    )}
                  </>
                )}
                {!selectedImageURL &&  (
                  <>
                    <Image
                    src={(productDetailData.images ? productDetailData.images[0].src : '')}
                    alt={productDetailData.name}
                    fluid
                    className='align-items-center'
                    />
                    {productDetailData.sale_price > 0 && (
                      <div class="sale-icon"></div>
                    )}
                  </>
                )}
                <div className='row'>
                  {productDetailData.images && productDetailData.images.map((image) => (
                     (
                      <div key={image.id} className='col-md-4 mt-3 image-bg-color'>
                        <a onClick={() => changeSelectedImage(image.src)}>
                          <Image key={image.id} src={image.src} alt={image.name} fluid />
                        </a>
                      </div>
                  )))}
                </div>
              </div>
            )}
            {/* Nếu kích thước màn hình < MD (768px) */}
            {productDetailData.images && window.innerWidth < 768 && (
              <div className='col-md-4 mb-2'>
                <div className='row'>
                    <Swiper
                      modules={[Navigation, Autoplay]}
                      spaceBetween={20}
                      loop={true} // Lặp lại các slide
                      navigation
                      autoplay={{
                        delay: 5000, // Thiết lập thời gian tự động chuyển slide là 3000ms (3 giây)
                        disableOnInteraction: false,
                      }}
                      slidesPerView={1}
                    >
                      <div className="swiper-button-prev" style={navigationStyle}></div>
                      <div className="swiper-button-next" style={navigationStyle}></div>
                      {productDetailData.images.map((image) => (
                        <SwiperSlide key={image.id}>
                          <div key={image.id} className='text-center image-bg-color'>
                              <Image key={image.id} src={image.src} alt={image.name} fluid className='mx-auto' />
                              {productDetailData.sale_price > 0 && (
                                <div class="sale-icon"></div>
                              )}
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                </div>
              </div>
            )}
            <div className='col-md-5 text-justify'>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <p className='product-header'>{productDetailData && productDetailData.name}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={productDetailData.average_rating}
                    text={`${productDetailData.rating_count} reviews`}
                    color={'#ea0429'}
                  />
                </ListGroup.Item>
                <ListGroup.Item>Giá gốc: {parseInt(productDetailData.regular_price).toLocaleString("vi", {
                                                    style: "currency",
                                                    currency: "VND",
                                                    })}</ListGroup.Item>
                {productDetailData.sale_price > 0 && (
                                <ListGroup.Item>Giá khuyến mãi: {parseInt(productDetailData.price).toLocaleString("vi", {
                                  style: "currency",
                                  currency: "VND",
                                  })}</ListGroup.Item>
                              )}
                <ListGroup.Item>
                  Mô tả: <TextCollapse text={productDetailData.description?productDetailData.description:''} maxLength={300} />
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className='col-md-3 text-justify'>
              <Card>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <Row>
                      <Col>Giá:</Col>
                      <Col>
                        <strong>{parseInt(productDetailData.price).toLocaleString("vi", {
                                                    style: "currency",
                                                    currency: "VND",
                                                    })}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Trạng thái:</Col>
                      <Col>
                        {productDetailData.stock_status === 'instock'
                          ? 'Còn hàng'
                          : 'Hết hàng'}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {productDetailData.stock_status === 'instock' && (
                    <ListGroup.Item>
                      <Row>
                        <Col>Số lượng</Col>
                        <Col>
                          <Form.Control
                            as='select'
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                          >
                            {/* {[...Array(product.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )} */}
                            {/* TODO: đang fix cứng số lượng sản phẩm đc chọn tối đa là 10 */}
                            {[
                              ...Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10).keys(),
                            ].map((x) => (
                              <option key={x + 1} value={x + 1}>
                                {x + 1}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item>
                    <Button
                      onClick={addToCartHandler}
                      className='btn btn-red'
                      type='button'
                      disabled={productDetailData.stock_status !== 'instock'}
                    >
                      Thêm vào giỏ hàng
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </div>
        </div>
        <div className='row d-flex'>
          <div className='col-md-12 mt-1'>
              <h4>ĐÁNH GIÁ SẢN PHẨM</h4>
              {!productDetailData.reviews && <Message>Sản phẩm chưa có đánh giá</Message>}
              <ListGroup variant='flush'>
                {productDetailData.reviews && productDetailData.reviews.map((review) => (
                  <ListGroup.Item key={review.id}>
                    <strong>{review.reviewer}</strong>
                    <Rating value={review.rating} color={'#ea0429'}/>
                    <p>{review.date_created.substring(0, 10)}</p>
                    <div dangerouslySetInnerHTML={{ __html: review.review?review.review:'' }} />
                  </ListGroup.Item>
                ))}
                {successProductReview && (
                      <Message variant='success'>
                        Đánh giá của bạn đang được duyệt. Cảm ơn bạn.
                      </Message>
                    )}
                {/* {loadingProductReview && <Loader />} */}
                {errorProductReview && (
                  <Message variant='danger'>{errorProductReview}</Message>
                )}
                {productDetailData.canReviewProduct && (
                    <ListGroup.Item>
                    <h2>Hãy đánh giá sản phẩm để nhận điểm thưởng</h2>
                    {userInfoData ? (
                      <Form onSubmit={submitHandler}>
                        <Form.Group controlId='rating'>
                          <Form.Label>Chất lượng sản phẩm</Form.Label>
                          <Form.Control
                            as='select'
                            value={rating}
                            required
                            onChange={(e) => setRating(e.target.value)}
                          >
                            <option value=''>Vui lòng chọn...</option>
                            <option value='1'>1 - Tệ</option>
                            <option value='2'>2 - Không hài lòng</option>
                            <option value='3'>3 - Bình thường</option>
                            <option value='4'>4 - Hài lòng</option>
                            <option value='5'>5 - Tuyệt vời</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='comment'>
                          <Form.Label>Chia sẻ nhận xét</Form.Label>
                          <Form.Control
                            as='textarea'
                            row='3'
                            value={comment}
                            required
                            onChange={(e) => setComment(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Button
                          disabled={loadingProductReview}
                          type='submit'
                          variant='primary'
                          className='btn btn-red'
                        >
                          Gửi
                        </Button>
                      </Form>
                    ) : (
                      <Message>
                        Vui lòng <Link to='/login'>đăng nhập</Link> để thực hiện đánh giá{' '}
                      </Message>
                    )}
                  </ListGroup.Item>
                )}
              </ListGroup>
          </div>
        </div>
      </div>
      </>
      )}
    </>
  )
}

export default ProductScreen
