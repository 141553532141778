import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { register } from "../actions/userActions";

const RegisterScreen = ({ location, history }) => {
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const userRegisterFromScreen = useSelector(
    (state) => state.userRegisterFromStore
  );
  const { loading, error, userInfoData } = userRegisterFromScreen;

  //const redirect = location.search ? location.search.split('=')[1] : '/'

  const redirect = "/initpass/?username=" + username;

  useEffect(() => {
    if (userInfoData) {
      history.push(redirect);
    }
  }, [history, userInfoData, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(register(username));
  };

  return (
    <div className="bg-red">
      <Container
        style={{
          minHeight: 600,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && (
          <div className="loading-full">
            <Loader />
          </div>
        )}
        <div className="box-login">
          <h1 className="titleSlide h3 mb-4">Đăng Ký</h1>
          {userInfoData && userInfoData.data && userInfoData.code === '00' && <Message variant="success">{userInfoData.message}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="username">
              <Form.Label className="titleProduct mx-1">
                Số điện thoại:
              </Form.Label>
              <Form.Control
                type="username"
                placeholder="Nhập Số điện thoại"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type="submit" className="btn btn-red w-100">
              Tiếp tục
            </Button>
          </Form>
          <hr className="my-3"></hr>
          <Row className="py-3">
            <Col>
              <p className="text-center mb-3">Bạn đã có tài khoản?</p>
              <Link
                className="btn btn-red"
                to={redirect ? `/login?redirect=${redirect}` : "/login"}
              >
                Đăng nhập
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default RegisterScreen;
