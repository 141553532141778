import React, { useState, useEffect, useContext } from 'react'
import { Form, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { updateOrderStatus } from '../actions/orderActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import Message from '../components/Message'
//Import LanguageContext được export từ App.js
import { LanguageContext } from '../App';
import OrderPopup from "../components/OrderPopup";
import Banner from "../components/Banner";


const LoyaltyScreen = ({ history }) => {
  const tableScroll = {
    maxHeight: "400px", // Điều chỉnh chiều cao của tbody để hiển thị thanh cuộn dọc khi nội dung vượt quá
    overflowY: "auto", // Tạo thanh cuộn dọc khi nội dung vượt quá chiều cao
    width: "100%",
  };
  const theadStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "#ea0429",
  };

  const [username, setUsername] = useState('')
  const [display_name, setDisplay_name] = useState('')
  const [shipping_address_1, setShipping_address_1] = useState('')
  const [email, setEmail] = useState('')
  const [old_pass, setOld_pass] = useState('')
  const [new_pass, setNew_pass] = useState('')

  const userDetailsFromScreen = useSelector((state) => state.userDetailsFromStore)
  const { loading, userDetailsData } = userDetailsFromScreen

/*CHÚ Ý QUAN TRỌNG
  - Sau khi login thành công thì lưu thông tin user vào đối tượng userInfoData (ở hàm userReducer.js -> userLoginReducer)
  - Đối tượng này được định nghĩa trong store.js
  - Đối tượng này sẽ mất đi khi chuyển/refesh trang 
  -> Để sử dụng đối tượng userInfoData trong các màn hình mà không cần dispatch lại API userLogin 
  -> Thì trong store.js sử dụng Local storage để lưu đối tượng userInfoFromStorage
      const userInfoFromStorage = localStorage.getItem('userInfoFromStorage')
      ? JSON.parse(localStorage.getItem('userInfoFromStorage'))
      : null
  - Sau đó hàm initialState sẽ gán đối tượng Local Storage userInfoFromStorage vào các đối tượng trong userInfoData
    const initialState = {
      cartFromStore: {
        cartItemsData: cartItemsFromStorage,
      },
      userInfoFromStore: { userInfoData: userInfoFromStorage },
      messageSettingsFromStore: messageSettingsFromStorage,
    }
  - Đối tượng này sẽ được sử dụng để kiểm tra user đã đăng nhập chưa và được sử dụng trong các components dùng chung như Header.js
  - Khác với đối tượng userInfoData (ở hàm userReducer.js -> userRegisterReducer) được dùng trong RegisterScreen
*/

  const userLoginFromScreen = useSelector((state) => state.userInfoFromStore)
  const { userInfoData } = userLoginFromScreen

  const userUpdateProfileFromScreen = useSelector((state) => state.userUpdateProfileFromStore)
  const { success, error} = userUpdateProfileFromScreen

  /* Nếu KH đã đăng nhập thì mỗi 1 phút component AutoRequestServer sẽ gọi request đến backend
    để lấy điểm tích luỹ-hạng thành viên và điểm đã sử dụng đưa vào userPointsAndMembershipFromStore */
  const customerPointsAndMembershipFromScreen = useSelector((state) => state.customerPointsAndMembershipFromStore)
  const {error:errorCustomerPoint, points, membership, listpoint, redeempoints, listredeempoint} = customerPointsAndMembershipFromScreen
  //Điểm tích luỹ
  const [listPointData, setListPointData] = useState([])
  //Điểm đã sử dụng
  const [listRedeemPointData, setListRedeemPointData] = useState([])


  //Lấy ngôn ngữ được chọn có phải tiếng Việt không từ biến dùng chung isVietnamese trong LanguageContext được khai báo trong App.js
  const { isVietnamese, setIsVietnamese } = useContext(LanguageContext);

  //messageSettingData chứa thông tin message như Trang chủ, Sản phẩm... cho các menu và label
  const messageSettingsFromScreen = useSelector((state) => state.messageSettingsFromStore);
  const { messageSettingData } = messageSettingsFromScreen;

  //isLogin biến xác định đã đăng nhập chưa
  const [isLogin, setIsLogin] = useState(false)

  // Ẩn/Hiện 3 tabs: Thông tin, Lịch sử tích điểm, Lịch sử sử dụng điểm
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  }

  // Ẩn/Hiện mật khẩu cũ/mới
  const [showChangePassword, setShowChangePassword] = useState(false);
  const handleCheckboxPasswordChange = (event) => {
    setShowChangePassword(event.target.checked);
  };

  //Ngày sinh
  const [birthday, setBirthday] = useState('');
  const handleBirthdayChange = (event) => {
    setBirthday(event.target.value);
  };

  //Popup chi tiết đơn hàng
  const [showPopup, setShowPopup] = useState(false);
  //Chứa ID của đơn hàng được chọn xem chi tiết
  const [selectedOrderId, setselectedOrderId] = useState("");
  //Sự kiện mở popup
  const handleOpenPopup = (orderId) => {
    setShowPopup(true);
    setselectedOrderId(orderId);
  };
  //Sự kiện đóng popup
  const handleClosePopup = () => {
    setShowPopup(false);
    //Reset lại selectedOrderId
    setselectedOrderId("");
  };
  //Sự kiện nhấn bên ngoài popup
  const handleOutsideClick = () => {
    handleClosePopup();
    //Reset lại selectedOrderId
    setselectedOrderId("");
  };

  const dispatch = useDispatch()

  useEffect(() => {
    if (!userInfoData) {//nếu chưa đăng nhập thì hiển thị chương trình hội viên
      setIsLogin(false)
    } else {
      setIsLogin(true)
      if (!userDetailsData || success) {//nếu đã gọi hàm updateUserProfile
        dispatch({ type: USER_UPDATE_PROFILE_RESET })//Code này cần để tránh request getUserDetails bị loop
        dispatch(getUserDetails(userInfoData.data.username))//Lấy thông tin customer
      } else {//nếu đã đăng nhập và vào trang profile thì hiển thị thông tin từ userInfoFromStorage -> data
        setUsername(userInfoData.data.username)
        setDisplay_name(userInfoData.data.display_name)
        setBirthday(userInfoData.data.birthday)
        setShipping_address_1(userInfoData.data.shipping_address_1)
        setEmail(userInfoData.data.email)
        //Nếu tồn tại listpoint/listredeempoint từ AutoRequestServer->userPointsAndMembershipFromStore thì sử dụng AutoRequestServer->userPointsAndMembershipFromStore->listpoint/listredeempoint
        //Ngược lại thì sử dụng listpoint/listredeempoint từ userInfoData.data.membership
        setListPointData(listpoint?listpoint:userInfoData.data.listpoint)
        setListRedeemPointData(listredeempoint?listredeempoint:userInfoData.data.listredeempoint)
      }
    }
  }, [dispatch, history, userInfoData, userDetailsData, success])

  const submitHandler = (e) => {
    e.preventDefault()
    //DISPATCH UPDATE PROFILE
    dispatch(updateUserProfile(username, display_name, birthday, shipping_address_1, email, showChangePassword, old_pass, new_pass))
  }

  const handleClick = (orderId) => {
    dispatch(updateOrderStatus(orderId, 'received'))
  }

  return (
    <div className='container'>
      {userDetailsData && userDetailsData.code === '00' && messageSettingData && <Message variant="success">{isVietnamese ? JSON.parse(messageSettingData[0].vietnamese)[userDetailsData.message]: JSON.parse(messageSettingData[0].english)[userDetailsData.message]}</Message>}
      {error && messageSettingData && <Message variant="danger">{isVietnamese ? JSON.parse(messageSettingData[0].vietnamese)[error] : JSON.parse(messageSettingData[0].english)[error]}</Message>}
      <div className="row justify-content-center">
          {loading ? (
            <Loader />
          ): !isLogin ? ( //Nếu chưa đăng nhập thì chỉ hiển thị chương trình hội viên
            <div className='col-md-12'>
              {/* Phần giới thiệu chương trình hội viên */}
              <a href='/register'>
                <div className='mt-2 mb-2'>
                  <Banner banner_app_type={"web"} banner_type={"loyalty"}/>
                </div>
              </a>
            </div>
          ):(
          <>
            <div className="tab-header">
              <button
                onClick={() => handleTabClick(1)}
                className={`tab-button ${activeTab === 1 ? 'active' : ''}`}
              >
                Hội viên
              </button>
              <button
                onClick={() => handleTabClick(2)}
                className={`tab-button ${activeTab === 2 ? 'active' : ''}`}
              >
                Tích điểm
              </button>
              <button
                onClick={() => handleTabClick(3)}
                className={`tab-button ${activeTab === 3 ? 'active' : ''}`}
              >
                Sử dụng điểm
              </button>
            </div>
            <div className="tab-content container">
              {activeTab === 1 && 
              <div className='d-flex flex-wrap'>
                <div className='col-md-4 bg-white'>
                  <Form onSubmit={submitHandler}>
                    <Form.Group controlId='display_name'>
                      <Form.Label>Họ và tên <span className='required'>*</span></Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Nhập họ và tên'
                        required
                        value={display_name}
                        onChange={(e) => setDisplay_name(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='username'>
                      <Form.Label>Số điện thoại <span className='required'>*</span></Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Nhập số điện thoại'
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        readOnly
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='shipping_address_1'>
                      <Form.Label>Địa chỉ <span className='required'>*</span></Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Nhập địa chỉ'
                        required
                        value={shipping_address_1}
                        onChange={(e) => setShipping_address_1(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='birthday'>
                      <Form.Label>Ngày sinh</Form.Label>
                      <Form.Control
                        type='date'
                        id="birthday"
                        name="birthday"
                        value={birthday}
                        onChange={handleBirthdayChange}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='email'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='Nhập email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="pass">
                      <label className="mx-3">
                        <input
                          type="checkbox"
                          checked={showChangePassword}
                          onChange={handleCheckboxPasswordChange}
                        />
                        <span className="mx-2">Đổi mật khẩu</span>
                      </label>
                      {showChangePassword && (
                        <>
                          <Form.Group controlId='old_pass'>
                            <Form.Label>Mật khẩu cũ</Form.Label>
                            <Form.Control
                              type='password'
                              placeholder='Nhập mật khẩu cũ'
                              required={showChangePassword}
                              value={old_pass}
                              onChange={(e) => setOld_pass(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId='new_pass'>
                            <Form.Label>Mật khẩu mới</Form.Label>
                            <Form.Control
                              type='password'
                              placeholder='Nhập mật khẩu mới'
                              required={showChangePassword}
                              value={new_pass}
                              onChange={(e) => setNew_pass(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </>
                      )}
                    </Form.Group>
                    <Button type='submit' variant='primary' className='btn btn-red'>
                      Cập nhật
                    </Button>
                  </Form>
                </div>
                <div className='col-md-8'>
                  {/* Phần giới thiệu chương trình hội viên */}
                  <Banner banner_app_type={"web"} banner_type={"loyalty"}/>
                </div>
              </div>}
              {activeTab === 2 && 
                <div className='container'>
                <div className='col-md-12 bg-white'>
                  <Form onSubmit={submitHandler}>
                    <Form.Group controlId='points'>
                      <Form.Label>Điểm tích luỹ</Form.Label>
                      <Form.Control
                        type='points'
                        readOnly
                        //Nếu tồn tại points từ AutoRequestServer->userPointsAndMembershipFromStore thì sử dụng AutoRequestServer->userPointsAndMembershipFromStore->points
                        //Ngược lại thì sử dụng points từ userInfoData.data.points
                        value={points?points:userInfoData.data.points}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='membership'>
                      <Form.Label>Hạng thành viên</Form.Label>
                      <Form.Control
                        type='membership'
                        readOnly
                        //Nếu tồn tại membership từ AutoRequestServer->userPointsAndMembershipFromStore thì sử dụng AutoRequestServer->userPointsAndMembershipFromStore->membership
                        //Ngược lại thì sử dụng membership từ userInfoData.data.membership
                        value={membership?membership:userInfoData.data.membership}
                      ></Form.Control>
                    </Form.Group>
                  </Form>

                  <div className="d-block justify-content-center mt-3">
                    {errorCustomerPoint ? (
                      <Message variant="danger">{errorCustomerPoint}</Message>
                    ) : (
                      <>
                        <h4 className="my-2">Lịch sử tích điểm</h4>
                        <div style={tableScroll}>
                            <table className="table table-striped table-bordered align-top mt-3">
                              <thead style={theadStyle}>
                                <tr>
                                  <th
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                      fontSize: "13px",
                                      width: "13%",
                                      padding: "0 2px",
                                    }}
                                  >
                                    Loại
                                  </th>
                                  <th
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                      fontSize: "13px",
                                      width: "10%",
                                      padding: "0 2px",
                                    }}
                                  >
                                    Số điểm
                                  </th>
                                  <th
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                      fontSize: "13px",
                                      width: "13%",
                                      padding: "0 2px",
                                    }}
                                  >
                                    Ngày
                                  </th>
                                  <th
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                      fontSize: "13px",
                                      width: "13%",
                                      padding: "0 2px",
                                    }}
                                  >
                                    Chi tiết
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {listPointData &&
                                  listPointData.map((pointItem) => (
                                    <>
                                      <tr key={pointItem.order_id?pointItem.order_id:pointItem.comment_id}>
                                        <td>{pointItem.order_id?'Mua hàng':pointItem.comment_id?'Đánh giá':pointItem.register_id?'Đăng ký':'Không xác định'}</td>
                                        <td>{pointItem.point}</td>
                                        <td>{pointItem.date_created}</td>
                                        <td style={{ textAlign: "center" }}>
                                          {pointItem.order_id && (
                                            <a
                                              onClick={() =>
                                                handleOpenPopup(pointItem.order_id)
                                              }
                                              className="mytooltip"
                                            >
                                              <p className="tooltip-text">
                                                Xem chi tiết
                                              </p>
                                              <span className="material-icons red">
                                                visibility
                                              </span>
                                            </a>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                              </tbody>
                            </table>
                        </div>
                      </>
                    )}
                    {/* Vị trí đặt popup */}
                    {showPopup && (
                      <OrderPopup
                        content={selectedOrderId}
                        onClose={handleClosePopup}
                        onOutsideClick={handleOutsideClick}
                      />
                    )}
                  </div>

                </div>
                <div></div>
              </div>}
              {activeTab === 3 && 
                <div className='container'>
                <div className='col-md-12 bg-white'>
                  <Form onSubmit={submitHandler}>
                      <Form.Group controlId='redeempoints'>
                        <Form.Label>Điểm đã sử dụng</Form.Label>
                        <Form.Control
                          type='redeempoints'
                          readOnly
                          //Nếu tồn tại redeempoints từ AutoRequestServer->userPointsAndMembershipFromStore thì sử dụng AutoRequestServer->userPointsAndMembershipFromStore->redeempoints
                          //Ngược lại thì sử dụng redeempoints từ userInfoData.data.redeempoints
                          value={redeempoints?redeempoints:userInfoData.data.redeempoints}
                        ></Form.Control>
                      </Form.Group>
                  </Form>
                  <div className="d-block justify-content-center mt-3">
                    {errorCustomerPoint ? (
                      <Message variant="danger">{errorCustomerPoint}</Message>
                    ) : (
                      <>
                        <h4 className="my-2">Lịch sử sử dụng điểm</h4>
                        <div style={tableScroll}>
                            <table className="table table-striped table-bordered align-top mt-3">
                              <thead style={theadStyle}>
                                <tr>
                                  <th
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                      fontSize: "13px",
                                      width: "10%",
                                      padding: "0 2px",
                                    }}
                                  >
                                    Đơn hàng
                                  </th>
                                  <th
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                      fontSize: "13px",
                                      width: "13%",
                                      padding: "0 2px",
                                    }}
                                  >
                                    Số điểm
                                  </th>
                                  <th
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                      fontSize: "13px",
                                      width: "13%",
                                      padding: "0 2px",
                                    }}
                                  >
                                    Ngày
                                  </th>
                                  <th
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                      fontSize: "13px",
                                      width: "13%",
                                      padding: "0 2px",
                                    }}
                                  >
                                    Chi tiết
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {listRedeemPointData &&
                                  listRedeemPointData.map((pointItem) => (
                                    <>
                                      <tr key={pointItem.order_id}>
                                        <td>{pointItem.order_id}</td>
                                        <td>{pointItem.point}</td>
                                        <td>{pointItem.date_created}</td>
                                        <td style={{ textAlign: "center" }}>
                                          {pointItem.order_id && (
                                            <a
                                              onClick={() =>
                                                handleOpenPopup(pointItem.order_id)
                                              }
                                              className="mytooltip"
                                            >
                                              <p className="tooltip-text">
                                                Xem chi tiết
                                              </p>
                                              <span className="material-icons red">
                                                visibility
                                              </span>
                                            </a>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                              </tbody>
                            </table>
                        </div>
                      </>
                    )}
                    {/* Vị trí đặt popup */}
                    {showPopup && (
                      <OrderPopup
                        content={selectedOrderId}
                        onClose={handleClosePopup}
                        onOutsideClick={handleOutsideClick}
                      />
                    )}
                  </div>

                </div>
                <div></div>
              </div>}
            </div>
        </>
          )}
      </div>
    </div>
  )
}

export default LoyaltyScreen
